<mat-dialog-content style="height: 380px; background-color: white;" (window:resize)="resetSize()">
  <div class="sameLine closeIcon" (click)='leaveDialog()'><img src="assets/pop_close_btn.png"></div>
  <div class="nextPage">
    <div class="rightDiv" *ngIf="pageIndex != this.bookData.length-2">
      <i class="fas fa-angle-right right1"></i>
      <i class="fas fa-arrow-right right2" (click)="nextPage(1)"></i>
    </div>
    <div class="rightDiv" *ngIf="pageIndex == this.bookData.length-2"></div>
    <div class="leftDiv" *ngIf="pageIndex != -1">
      <i class="fas fa-angle-left left1"></i>
      <i class="fas fa-arrow-left left2" (click)="nextPage(-1)"></i>
    </div>
    <div class="leftDiv" *ngIf="pageIndex == -1"></div>
  </div>
  <div class="listDiv">
    <div class="listChild{{i}} page" [ngStyle]="{'z-index': 1050-i-1}" *ngFor="let data of bookData; let i = index;">
      <img class="pageLeft imgStyle" src="{{imgPre}}{{data.leftimage}}">
      <img class="pageRight imgStyle" src="{{imgPre}}{{data.rightimage}}">
      <div class="bookTxt">
        <label>{{data.jobtitle}}</label><br>
        <label>{{data.contact}}</label><br>
        <label><a href="mailto:{{data.email}}">{{data.email}}</a></label>
      </div>
    </div>
    <div class="lastChild">
      <img class="pageLeft imgStyle" src="{{imgPre}}{{bookData[0].leftimage}}">
      <img class="pageRight imgStyle" src="{{imgPre}}{{bookData[0].rightimage}}">
    </div>
    <!--<div class="listChild0 page" [ngStyle]="{'z-index': 1050-1}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/akiraL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/akiraR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/akiraL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/akiraR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild1 page" [ngStyle]="{'z-index': 1050-2}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/claireL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/claireR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/claireL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/claireR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild2 page" [ngStyle]="{'z-index': 1050-3}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/emmaL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/emmaR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/emmaL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/emmaR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild3 page" [ngStyle]="{'z-index': 1050-4}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/sierraL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/sierraR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/sierraL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/sierraR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild4 page" [ngStyle]="{'z-index': 1050-5}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/angelaL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/angelaR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/angelaL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/angelaR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild5 page" [ngStyle]="{'z-index': 1050-6}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/heraL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/heraR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/heraL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/heraR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild6 page" [ngStyle]="{'z-index': 1050-7}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/kevinL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/kevinR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/kevinL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/kevinR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="listChild7 page" [ngStyle]="{'z-index': 1050-8}">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/zoeyL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/zoeyR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/zoeyL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/zoeyR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
    <!--<div class="lastChild">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/en_people/akiraL_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/en_people/akiraR_E.png" *ngIf="currentLang == 'en-GB'">-->
      <!--<img class="pageLeft imgStyle" src="assets/person/tw_people/akiraL_T.png" *ngIf="currentLang == 'zh-TW'">-->
      <!--<img class="pageRight imgStyle" src="assets/person/tw_people/akiraR_T.png" *ngIf="currentLang == 'zh-TW'">-->
    <!--</div>-->
  </div>
</mat-dialog-content>