<div id="loading" class="loading" align="center" *ngIf="showLoading == 1 || showLoading == 2 || showLoading == 3 || showLoading == 4">
  <mat-progress-spinner class="spinnerStyle" mode="indeterminate" *ngIf="showLoading == 1"></mat-progress-spinner>
  <button class="checkBtnStyle" mat-raised-button mat-icon-button color="primary" *ngIf="showLoading == 2"><mat-icon class="checkIconStyle">check</mat-icon></button>
  <button class="checkBtnStyle" mat-raised-button mat-icon-button color="accent" *ngIf="showLoading == 3"><mat-icon class="checkIconStyle">error</mat-icon></button>
  <button class="checkBtnStyle" mat-raised-button mat-icon-button color="primary" *ngIf="showLoading == 4"><mat-icon class="checkIconStyle">delete</mat-icon></button>
</div>
<div class="backManagerCon">
  <div class="backMainTitle">
    <img class="inline_style logoTitle" src="assets/logo.png">
    <h2 class="maintTitle inline_style"><strong>新眺創意實驗室</strong></h2>
  </div>
  <div class="backLeftBar">
    <!--<button class="leftBarFake"></button>-->
    <button class="leftBarBtn active" (click)="changeLeftTab(0)">階段文字</button>
    <button class="leftBarBtn" (click)="changeLeftTab(1)">成員圖片</button>
    <button class="leftBarBtn" (click)="changeLeftTab(2)">文章分類</button>
    <button class="leftBarBtn" (click)="changeLeftTab(3)">療癒影片</button>
    <button class="leftBarBtn" (click)="changeLeftTab(4)" *ngIf="accountType == 2">帳號管理</button>
    <button class="leftBarBtn" (click)="logOut()">登出</button>
  </div>
  <!--[ngStyle]="{'visibility': leftBarTab != 0 ? 'visible' : 'hidden'}"-->
  <div class="backSubTitle">
    <button class="subTitleBtn" (click)="changeSubTitle(0)" [ngStyle]="{'visibility': leftBarTab != 0 ? 'visible' : 'hidden'}">新增</button>
    <button class="subTitleBtn" (click)="changeSubTitle(1)">修改</button>
    <button class="subTitleBtn" (click)="changeSubTitle(2)" [ngStyle]="{'visibility': leftBarTab != 0 ? 'visible' : 'hidden'}">刪除</button>
  </div>
  <div class="backContent">
    <app-step-text *ngIf="leftBarTab == 0" (loadingComponent)="showLoadDiv($event)" [(subTitleTab)]="subTitleTab" [(leftBarTab)]="leftBarTab"></app-step-text>
    <app-member *ngIf="leftBarTab == 1" (loadingComponent)="showLoadDiv($event)" [(subTitleTab)]="subTitleTab" [(leftBarTab)]="leftBarTab"></app-member>
    <app-works *ngIf="leftBarTab == 2" (loadingComponent)="showLoadDiv($event)" [(subTitleTab)]="subTitleTab" [(leftBarTab)]="leftBarTab"></app-works>
    <app-video *ngIf="leftBarTab == 3" (loadingComponent)="showLoadDiv($event)" [(subTitleTab)]="subTitleTab" [(leftBarTab)]="leftBarTab"></app-video>
    <app-account *ngIf="leftBarTab == 4" (loadingComponent)="showLoadDiv($event)" [(subTitleTab)]="subTitleTab" [(leftBarTab)]="leftBarTab"></app-account>
  </div>
</div>