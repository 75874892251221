import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import * as globalVar from "../../globalVar";

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.css']
})
export class WorksComponent implements OnInit, OnChanges {

  worksForm: FormGroup;
  dataArr = [];         // 資料集
  extendIndex = -1;     // 要伸展開的項目
  modify = false;       // 是否修改資料

  showImage = 0;
  imageUrl = "";
  imageData = "";

  @Input() subTitleTab;     // 新增、修改
  @Input() leftBarTab;      // 文章分類(2)
  @Output('loadingComponent') loadingComponent = new EventEmitter<number>();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  ngOnChanges(changeItems) {
      if(changeItems.subTitleTab) {
          if(this.subTitleTab == 0) {     // 新增
              this.setValue(0);
          }
          else if(this.subTitleTab == 1 || this.subTitleTab == 2) {     // 修改、刪除
              this.worksForm = new FormGroup({
                  type: new FormControl('1', Validators.required),
                  chtitle: new FormControl('', Validators.required),
                  entitle: new FormControl('', Validators.required),
                  url: new FormControl('', Validators.required),
                  image: new FormControl(''),
                  imageCheck: new FormControl('', Validators.required)
              });
              this.searchData();
          }
      }
      else if(changeItems.leftBarTab && this.leftBarTab == 2) {
          this.setValue(0);
      }
  }

  // 搜尋資料
  searchData() {
      let formData = new FormData();
      formData.append('type', this.worksForm.get('type').value);

      this.http.post(globalVar.httpShowURL+'infos?x=4', formData).subscribe(
          (res:any) => {
              this.dataArr = res;
          }
      )
  }

  // 改變資料類別
  changeType() {
      if(this.subTitleTab == 1 || this.subTitleTab == 2) {     // 修改、刪除
          this.searchData();
      }
  }

  onSubmit(value) {

  }

  // 將項目伸展開
  extendTab(index) {
      this.modify = false;
      if(this.extendIndex == -1) {        // 目前沒開啟的項目，直接展開
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
      else if(this.extendIndex == index) {        // 點擊開啟的項目，關閉此項目
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          this.extendIndex = -1;
      }
      else {        // 已有開啟項目，點擊非開啟的項目，項目一開一關
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
  }

  // 設定表單欄位
  setValue(index) {
      if(this.subTitleTab == 0) {       // 新增
          this.worksForm = new FormGroup({
              type: new FormControl('1', Validators.required),
              chtitle: new FormControl('', Validators.required),
              entitle: new FormControl('', Validators.required),
              url: new FormControl('', Validators.required),
              image: new FormControl(''),
              imageCheck: new FormControl('', Validators.required)
          });
          this.showImage = 0;
          this.imageUrl = "";
          this.imageData = "";
      }
      else if(this.subTitleTab == 1 || this.subTitleTab == 2) {       // 修改、刪除
          this.worksForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: true});
          this.worksForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: true});
          this.worksForm.get('url').reset({value: this.dataArr[index].linkurl, disabled: true});
          this.imageUrl = globalVar.httpGetImgURL + this.dataArr[index].image;
          this.showImage = 1;
          this.imageData = "";
      }
  }

  // 修改
  modifyData(index) {
      this.modify = true;
      this.worksForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: false}, Validators.required);
      this.worksForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: false}, Validators.required);
      this.worksForm.get('url').reset({value: this.dataArr[index].linkurl, disabled: false}, Validators.required);
  }

  // 取消修改
  cancel(index) {
      this.worksForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: true});
      this.worksForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: true});
      this.worksForm.get('url').reset({value: this.dataArr[index].linkurl, disabled: true});
      this.modify = false;
  }

  // 新增圖片
  addImage(index) {
      if(this.subTitleTab == 1) {       // 修改
          this.worksForm.get('imageCheck').reset('1', Validators.required);
      }

      if(!this.worksForm.invalid) {
          this.loadingComponent.emit(1);

          if(this.imageData != '') {
              let imageFormData = new FormData();
              imageFormData.append("upload[]", this.imageData);

              this.http.post(globalVar.httpImgURL+"AlbumUpload", imageFormData).subscribe(
                  (res:any) => {
                      this.saveData(res[0].imageUrl ,index);
                  }
              );
          }
          else {      // 未改圖片
            this.saveData(this.dataArr[index].image ,index);
          }
      }
      else {
          alert("請確認欄位、圖片是否皆有填寫");
      }
  }

  // 送出修改
  saveData(imageUrl ,index) {
      this.loadingComponent.emit(1);

      let formData = new FormData();
      if(this.subTitleTab == 0) {     // 新增
          formData.append('id', '');
      }
      else if(this.subTitleTab == 1) {      // 修改
          formData.append('id', this.dataArr[index].id);
      }
      formData.append('type', this.worksForm.get('type').value);
      formData.append('chtitle', this.worksForm.get('chtitle').value);
      formData.append('entitle', this.worksForm.get('entitle').value);
      formData.append('url', this.worksForm.get('url').value);
      formData.append('image', imageUrl);

      this.http.post(globalVar.httpBackURL+'x=4', formData).subscribe(
          (res:any) => {
              if(res[0].Response == 1) {
                  if(this.subTitleTab == 0) {
                      this.worksForm.get('chtitle').reset({value: '', disabled: false}, Validators.required);
                      this.worksForm.get('entitle').reset({value: '', disabled: false}, Validators.required);
                      this.worksForm.get('url').reset({value: '', disabled: false}, Validators.required);
                      this.worksForm.get('image').reset({value: '', disabled: false});
                      this.worksForm.get('imageCheck').reset('', Validators.required);
                      this.imageUrl = "";
                  }
                  else {
                      this.dataArr[index].chtitle = this.worksForm.get('chtitle').value;
                      this.dataArr[index].entitle = this.worksForm.get('entitle').value;
                      this.dataArr[index].linkurl = this.worksForm.get('url').value;
                      this.dataArr[index].image = imageUrl;
                      this.worksForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: true}, Validators.required);
                      this.worksForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: true}, Validators.required);
                      this.worksForm.get('url').reset({value: this.dataArr[index].linkurl, disabled: true}, Validators.required);
                      this.worksForm.get('image').reset({value: '', disabled: false});
                      this.worksForm.get('imageCheck').reset('', Validators.required);
                      this.imageUrl = globalVar.httpGetImgURL + imageUrl;
                      this.modify = false;
                  }

                  this.loadingComponent.emit(2);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
          }
      )
  }

  // 刪除資料
  deleteImage(index) {
      if(confirm("是否確定刪除?")) {
          this.loadingComponent.emit(1);

          let formData = new FormData();
          formData.append('FileName', this.dataArr[index].image);

          this.http.post(globalVar.httpImgURL+'DeleteFile', formData).subscribe(
              (res:any) => {
                  if(res[0] == true) {
                      this.deleteData(index);
                  }
                  else {
                      this.loadingComponent.emit(3);
                  }
              }
          );
      }
  }

  // 刪除資料
  deleteData(index) {
      let formData = new FormData();
      formData.append('id', this.dataArr[index].id);

      this.http.post(globalVar.httpBackURL+'x=9', formData).subscribe(
          (res:any) => {
              if(res[0].Response == 1) {
                  this.dataArr.splice(index, 1);
                  this.loadingComponent.emit(4);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
          }
      );
  }

  handleInput(images: any) {
      if(images.length != 0) {   // 至少一個圖檔
          let reader = new FileReader();
          reader.onload = (event: any) => {
              this.imageUrl = event.target.result;    // 所有圖皆有小圖
          }
          reader.readAsDataURL(images[0]);

          this.imageData = images[0];
          this.showImage = 1;
          this.worksForm.get('imageCheck').reset("1", Validators.required);
      }
      else {
          this.imageUrl = "";
          this.imageData = "";
          this.showImage = 0;
          this.worksForm.get('imageCheck').reset("", Validators.required);      // 圖片沒傳
      }
  }

}
