import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from "./back/login/login.component";
import { ManagerComponent } from "./back/manager/manager.component";
import { StepTextComponent } from "./back/step-text/step-text.component";
import { MemberComponent } from "./back/member/member.component";
import { WorksComponent } from "./back/works/works.component";
import { VideoComponent } from "./back/video/video.component";

const routes: Routes = [
    {
        path: 'BackLogin',
        component: LoginComponent
    },
    {
        path: 'BackManager',
        component: ManagerComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
