<!--The content below is only a placeholder and can be replaced.-->
<!--<div class='bg' [ngStyle]="{'background-image': 'url(' + imageurl + ')'}"></div>-->

<div *ngIf="!backView">
  <div *ngIf="loaging" class="loadingPage">
    <div class="stairs_box l">
      <div class="box_left"></div>
      <div class="box_right"></div>
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <br />
    <!-- <p class="loadingPct">{{loadingTxt}}%</p> -->
  </div>
  <!-- loadingPage end -->

  <!-- header strat -->
  <div class="headNavBar" *ngIf="!loaging">
    <div class="menuBtn" (click)="openMenu()">
      <div class="stairs_box s">
        <div class="box_left"></div>
        <div class="box_right"></div>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="social_box">
      <div class="share_Btn">
        <a
          href="https://www.facebook.com/pg/heartbeatcreativelab/posts/?ref=page_internal"
          target="_blank"
          ><i class="fa fa-facebook"></i
        ></a>
      </div>
      <div class="language">
        <input
          type="checkbox"
          class="ios8-switch ios8-switch-lg"
          id="languageSwitch"
          (change)="onLangChange($event)"
        />
        <label for="languageSwitch">
          <div class="switch_text"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="menuCon">
    <div class="closeBtn" (click)="closeMenu()">
      <img src="assets/pop_close_btn.png" />
    </div>
    <div class="menuBox">
      <div class="menu_btn_con">
        <div class="menu_bar left"></div>
        <div class="menu_bar right"></div>
        <ul>
          <li [ngClass]="{ active: nowStage == 6 }">
            <div (click)="go(676)" *ngIf="nowStepData.length != 0">
              {{ nowStepData[6].title }}
            </div>
          </li>
          <li [ngClass]="{ active: nowStage == 5 }">
            <div (click)="go(619)" *ngIf="nowStepData.length != 0">
              {{ nowStepData[5].title }}
            </div>
          </li>
          <li [ngClass]="{ active: nowStage == 4 }">
            <div (click)="go(476)" *ngIf="nowStepData.length != 0">
              {{ nowStepData[4].title }}
            </div>
          </li>
          <li [ngClass]="{ active: nowStage == 3 }">
            <div (click)="go(346)" *ngIf="nowStepData.length != 0">
              {{ nowStepData[3].title }}
            </div>
          </li>
          <li [ngClass]="{ active: nowStage == 2 }">
            <div (click)="go(250)" *ngIf="nowStepData.length != 0">
              {{ nowStepData[2].title }}
            </div>
          </li>
          <li [ngClass]="{ active: nowStage == 1 }">
            <div (click)="go(136)" *ngIf="nowStepData.length != 0">
              {{ nowStepData[1].title }}
            </div>
          </li>
          <li [ngClass]="{ active: nowStage == 0 }">
            <div (click)="go(1)" *ngIf="nowStepData.length != 0">
              {{ "ladder.zeroStep" | translate }}
            </div>
          </li>
        </ul>
      </div>
      <img class="shadowStyle" src="assets/shadow.png" />
    </div>
  </div>
  <div class="menuColCon"></div>
  <!-- header end -->
  <!-- scrollBtn strat -->
  <div class="scrollBtn_con">
    <div class="scrollBtn">
      <!--<a href="javascript:;"><img src="assets/scrollBtn.png"></a>-->
      <a>
        <div class="scrollArrowColorV"></div>
        <div class="scrollArrowColorH"></div>
        <i class="fas fa-chevron-circle-down scrollIcon"></i>
      </a>
      <p class="scrollTxt" *ngIf="count != 757">
        {{ "scroll.down" | translate }}
      </p>
      <p class="scrollTxt" style="font-size: 12.5px" *ngIf="count == 757">
        {{ "scroll.reverse" | translate }}
      </p>
    </div>
  </div>
  <!-- scrollBtn end -->
  <!--(load)="nextImage()"-->
  <div class="imageVertical" (window:resize)="setWindowSize($event)">
    <!--額外區塊呈現(抽屜、書本)-->
    <div id="matchImage" class="matchImage">
      <div class="center_con" align="center">
        <div
          class="firstBox box pointer"
          *ngIf="showFivBoxClick"
          (click)="openDrawer(0)"
        ></div>
        <div
          class="secondBox box pointer"
          *ngIf="showFivBoxClick"
          (click)="openDrawer(1)"
        ></div>
        <div
          class="thirdBox box pointer"
          *ngIf="showFivBoxClick"
          (click)="openDrawer(2)"
        ></div>
        <div
          class="fourthBox box pointer"
          *ngIf="showFivBoxClick"
          (click)="openDrawer(3)"
        ></div>
        <div
          class="fifthBox box pointer"
          *ngIf="showFivBoxClick"
          (click)="openDrawer(6)"
        ></div>

        <div class="videoDownBox" *ngIf="showVideoClick" align="center">
          <img class="tv_style" src="assets/tv.png" />
        </div>
        <img
          id="videoImage"
          class="pointer"
          src="assets/owl.video.play.png"
          *ngIf="showVideoClick"
          (click)="openVideo()"
        />
        <!--<div class="videoUpBox" *ngIf="showVideoClick" align="center">-->
        <!--<div id="playDiv" class="pointer" (click)="openVideo()">-->
        <!--<img id="videoImage" src="assets/owl.video.play.png">&lt;!&ndash;(mouseenter)="waterAnimation('up')" (mouseleave)="waterAnimation('down')"&ndash;&gt;-->
        <!--<img class="waterImage" src="assets/water.png">-->
        <!--</div>-->
        <!--</div>-->

        <div class="searchMan" *ngIf="count == 676" (click)="toOMG()"></div>
        <div class="searchManArrow" *ngIf="count == 676">
          <div class="moveIcon">
            <div class="searchMan_con">
              <i class="fas fa-chevron-circle-down scrollIcon toLeftIcon"></i>
            </div>
            <div class="searchMan_txt">
              <span>{{ "scroll.click" | translate }}</span>
            </div>
          </div>
        </div>

        <div class="omgTxt" *ngIf="count == 688">
          <div id="omgInputDiv" class="omgInputDiv">
            <input
              #inputO
              type="text"
              class="omgInput"
              maxlength="1"
              (change)="matchOMG(inputO.value, inputM.value, inputG.value)"
              (keyup)="checkInputTxt(0, $event)"
            />
            <input
              #inputM
              type="text"
              class="omgInput"
              maxlength="1"
              (change)="matchOMG(inputO.value, inputM.value, inputG.value)"
              (keyup)="checkInputTxt(1, $event)"
            />
            <input
              #inputG
              type="text"
              class="omgInput"
              maxlength="1"
              (change)="matchOMG(inputO.value, inputM.value, inputG.value)"
              (keyup)="checkInputTxt(2, $event)"
            />
          </div>
          <img class="omgImg" src="assets/Bg_g_d.png" />
          <div class="omgArrow">
            <div class="moveIcon">
              <div class="searchMan_con">
                <i class="fas fa-chevron-circle-down scrollIcon toLeftIcon"></i>
              </div>
              <div class="searchMan_txt">
                <span>{{ "scroll.type" | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="book0 bookList bookNormalStyle pointer"
        *ngIf="showFivBookClick && nowBookData[0]"
        (click)="openBook(0)"
      >
        <div class="page1">
          <img
            class="bookImage1"
            src="{{ imgPre }}{{ nowBookData[0].leftimage }}"
          />
        </div>
        <div class="page2">
          <img
            class="bookImage2"
            src="{{ imgPre }}{{ nowBookData[0].rightimage }}"
          />
        </div>
      </div>
      <div
        class="book1 bookList bookNormalStyle pointer"
        *ngIf="showFivBookClick && nowBookData[1]"
        (click)="openBook(1)"
      >
        <div class="page1">
          <img
            class="bookImage1"
            src="{{ imgPre }}{{ nowBookData[1].leftimage }}"
          />
        </div>
        <div class="page2">
          <img
            class="bookImage2"
            src="{{ imgPre }}{{ nowBookData[1].rightimage }}"
          />
        </div>
      </div>
      <div
        class="book2 bookList bookNormalStyle pointer"
        *ngIf="showFivBookClick && nowBookData[2]"
        (click)="openBook(2)"
      >
        <div class="page1">
          <img
            class="bookImage1"
            src="{{ imgPre }}{{ nowBookData[2].leftimage }}"
          />
        </div>
        <div class="page2">
          <img
            class="bookImage2"
            src="{{ imgPre }}{{ nowBookData[2].rightimage }}"
          />
        </div>
      </div>
      <div
        class="book3 bookList bookNormalStyle pointer"
        *ngIf="showFivBookClick && nowBookData[3]"
        (click)="openBook(3)"
      >
        <div class="page1">
          <img
            class="bookImage1"
            src="{{ imgPre }}{{ nowBookData[3].leftimage }}"
          />
        </div>
        <div class="page2">
          <img
            class="bookImage2"
            src="{{ imgPre }}{{ nowBookData[3].rightimage }}"
          />
        </div>
      </div>
      <div
        class="book4 bookList bookNormalStyle pointer"
        *ngIf="showFivBookClick && nowBookData[4]"
        (click)="openBook(4)"
      >
        <div class="page1">
          <img
            class="bookImage1"
            src="{{ imgPre }}{{ nowBookData[4].leftimage }}"
          />
        </div>
        <div class="page2">
          <img
            class="bookImage2"
            src="{{ imgPre }}{{ nowBookData[4].rightimage }}"
          />
        </div>
      </div>
      <div
        class="book5 bookList bookNormalStyle pointer"
        *ngIf="showFivBookClick && nowBookData[5]"
        (click)="openBook(5)"
      >
        <div class="page1">
          <img
            class="bookImage1"
            src="{{ imgPre }}{{ nowBookData[5].leftimage }}"
          />
        </div>
        <div class="page2">
          <img
            class="bookImage2"
            src="{{ imgPre }}{{ nowBookData[5].rightimage }}"
          />
        </div>
      </div>
    </div>

    <!--底圖-->
    <!--<img id="imageId" src="assets/heartbeatimage/action_0415/{{imageurl}}">-->
    <img id="imageId" src="assets/compress/{{ imageurl }}" />
  </div>

  <!--文字呈現-->
  <div id="stage">
    <div id="txt" class="desc">
      <div class="showText" *ngIf="count > 0 && count < 21">
        <div
          style="white-space: pre-line"
          class="smallText"
          *ngIf="helloName == 1"
        >
          {{ "zeroArea.morning" | translate }}
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          *ngIf="helloName == 2"
        >
          {{ "zeroArea.afternoon" | translate }}
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          *ngIf="helloName == 3"
        >
          {{ "zeroArea.evening" | translate }}
        </div>
        <div class="bigText">
          <div
            *ngFor="let title of stepTitle[0]; let i = index"
            style="display: block"
          >
            <span>{{ title }}</span>
          </div>
        </div>
        <!--<div style="white-space: pre-line" class="smallText" [innerHTML]="nowStepData[0].content" *ngIf="nowStepData.length != 0"></div>-->
      </div>
      <div class="showText" *ngIf="count > 115 && count < 157">
        <!--<div class="bigText">{{'firstArea.title' | translate}}</div>-->
        <div class="bigText">
          <div
            class="div_inline"
            *ngFor="let title of stepTitle[1]; let i = index"
          >
            <span>{{ title }}</span>
            <span class="div_inline" *ngIf="title == ' '">&nbsp;</span>
          </div>
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[1].content"
          *ngIf="nowStepData.length != 0"
        ></div>
      </div>
      <div class="showText" *ngIf="count > 229 && count < 271">
        <div class="bigText">
          <div
            class="div_inline"
            *ngFor="let title of stepTitle[2]; let i = index"
          >
            <span>{{ title }}</span>
            <span class="div_inline" *ngIf="title == ' '">&nbsp;</span>
          </div>
          <!--<div *ngFor="let title of stepTitle[2]; let i = index" style="display: inline-block">-->
          <!--{{title}}&nbsp;&nbsp;-->
          <!--</div>-->
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[2].content"
          *ngIf="nowStepData.length != 0"
        ></div>
      </div>
      <div class="showText" *ngIf="count > 325 && count < 367">
        <div class="bigText">
          <div
            class="div_inline"
            *ngFor="let title of stepTitle[3]; let i = index"
          >
            <span>{{ title }}</span>
            <div class="div_inline" *ngIf="title == ' '">&nbsp;</div>
          </div>
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[3].content"
          *ngIf="nowStepData.length != 0"
        ></div>
      </div>
      <div class="showText" class="showText" *ngIf="count > 455 && count < 497">
        <div class="bigText">
          <div
            [ngClass]="{ div_inline: true, 'm-l--10': i == 7 }"
            *ngFor="let title of stepTitle[4]; let i = index"
          >
            <span>{{ title }}</span>
            <div class="div_block" *ngIf="title == ' ' && i != 4"></div>
          </div>
          <!--<div *ngFor="let title of stepTitle[4]; let i = index" [ngStyle]="{'display': i == stepTitle[4].length-1 ? 'block' : 'inline-block'}">-->
          <!--{{title}}&nbsp;&nbsp;-->
          <!--</div>-->
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[4].content"
          *ngIf="nowStepData.length != 0"
        ></div>
      </div>
      <div class="showText" *ngIf="count > 598 && count < 640">
        <div class="bigText" style="padding-left: 10px">
          <div
            class="div_inline"
            *ngFor="let title of stepTitle[5]; let i = index"
          >
            <span>{{ title }}</span>
            <div class="div_inline" *ngIf="title == ' '">&nbsp;</div>
          </div>
        </div>
        <div
          class="smallText"
          style="white-space: pre-line; padding-left: 5px"
          [innerHTML]="awardsArr[awardsIndex]"
          *ngIf="nowStepData.length != 0"
        ></div>
        <div class="nextPageAwards">
          <div
            class="rightDivAwards"
            *ngIf="awardsIndex != awardsArr.length - 1"
          >
            <i class="fas fa-angle-right right1"></i>
            <i class="fas fa-arrow-right right2" (click)="nextAwards(1)"></i>
          </div>
          <div
            class="rightDivAwards"
            *ngIf="awardsIndex == awardsArr.length - 1"
          ></div>
          <div class="leftDivAwards" *ngIf="awardsIndex != 0">
            <i class="fas fa-angle-left left1"></i>
            <i class="fas fa-arrow-left left2" (click)="nextAwards(-1)"></i>
          </div>
          <div class="leftDivAwards" *ngIf="awardsIndex == 0"></div>
        </div>
      </div>
      <div class="showText" *ngIf="count > 655 && count < 697">
        <div class="bigText">
          <div
            class="div_inline"
            *ngFor="let title of stepTitle[6]; let i = index"
          >
            <span>{{ title }}</span>
            <span class="div_inline" *ngIf="title == ' '">&nbsp;</span>
          </div>
          <!--<div style="margin-bottom: 0px;" *ngFor="let title of stepTitle[6]; let i = index" style="display: inline-block">-->
          <!--{{title}}&nbsp;&nbsp;-->
          <!--</div>-->
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[6].content"
          *ngIf="count != 688 && nowStepData.length != 0"
        ></div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[7].content"
          *ngIf="count == 688 && nowStepData.length != 0"
        ></div>
      </div>
      <div class="showText" *ngIf="count > 736">
        <div class="smallText">
          {{ "seventhArea.content" | translate }}
        </div>
        <div class="bigText">
          <div
            [ngClass]="{ div_inline: true, 'm-l--10': i == 10 || i == 19 }"
            *ngFor="let title of stepTitle[8]; let i = index"
          >
            <span>{{ title }}</span>
            <div class="div_block" *ngIf="title == ' '"></div>
          </div>
        </div>
        <div
          style="white-space: pre-line"
          class="smallText"
          [innerHTML]="nowStepData[8].content"
          *ngIf="nowStepData.length != 0"
        ></div>
      </div>
    </div>
  </div>
  <!--<div>-->
  <!--<audio id="windSound" src="assets/windSound.mp4"></audio>-->
  <!--</div>-->
  <div class="footer">@2019 Heartbeat Creative Lab. All Rights Reserved.</div>
</div>

<router-outlet></router-outlet>
