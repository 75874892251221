<form (ngSubmit)="onSubmit(stepTextForm)" [formGroup]="stepTextForm">
  <select class="selectMargin" formControlName="language" (change)="langChange()">
    <option value="1">中文</option>
    <option value="2">英文</option>
  </select><br>
  <select class="selectMargin" formControlName="stepName" *ngIf="subTitleTab == 0">
    <option value="1">第一階段</option>
    <option value="2">第二階段</option>
    <option value="3">第三階段</option>
    <option value="4">第四階段</option>
    <option value="5">第五階段</option>
    <option value="6">第六階段</option>
    <option value="7">第七階段</option>
    <option value="8">第八階段</option>
    <option value="9">第九階段</option>
  </select><br>
  <div class="area_con" *ngIf="subTitleTab == 0">
    <div class="itemStyle">
      <div class="alignRight" style="padding: 10px;">
        <input placeholder="標題" class="inputSize" type="text" formControlName="title"><br><br>
        <textarea placeholder="內文" class="textareaSize" rows="5" formControlName="content"></textarea><br><br>
        <button class="backBtnStyle btnDanger btnSpace" (click)="saveData(-1)">送出</button>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 1">
    <div class="itemStyle" *ngFor="let data of nowDataArr; let i = index;">
      <div class="extendTitle" (click)="extendTab(i)">
        <h5 class="titleName">{{data.title}}</h5>
        <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
        <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
      </div>
      <div class="extendContent">
        <input placeholder="標題" class="inputSize" type="text" formControlName="title"><br><br>
        <textarea placeholder="內文" class="textareaSize" rows="5" formControlName="content"></textarea><br><br>
        <div class="alignRight">
          <button class="backBtnStyle btnWarn" *ngIf="!modify" (click)="modifyData(i)">修改</button>
          <button class="backBtnStyle btnWarn" *ngIf="modify" (click)="cancel(i)">取消</button>
          <button class="backBtnStyle btnDanger btnSpace" *ngIf="modify" (click)="saveData(i)">送出</button>
        </div>
      </div>
    </div>
  </div>
</form>