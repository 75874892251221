import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as $ from "jquery";
import * as globalVar from "../../globalVar";

@Component({
  selector: 'app-book-dialog',
  templateUrl: './book-dialog.component.html',
  styleUrls: ['./book-dialog.component.scss']
})
export class BookDialogComponent implements OnInit {

  pageIndex = -1;      // 頁面index
  bookData = [];        // DB資料
  currentLang;      // 目前的語言
  imgPre = globalVar.httpGetImgURL;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<any>, public translateService: TranslateService) {
      this.pageIndex = this.data.bookIndex-1;
      this.bookData = this.data.bookData;
      this.currentLang = this.translateService.store.currentLang;
  }

  ngOnInit() {
    setTimeout(() => {
        for(let i=0; i<this.data.bookIndex; i++) {
            $('.listChild'+i).addClass('active');
        }
    }, 100);

    this.resetSize();

    setTimeout(() => {
        $('.cdk-overlay-container').css({"z-index": 1020});
    }, 1000);
  }

  // 重置大小
  resetSize() {
      setTimeout(() => {
          $(".cdk-overlay-pane").css({
              'height': $('.bookImage1').eq(this.data.bookIndex).innerHeight()+"px",
              'max-width': '100vw'
          });
          $('mat-dialog-content').css('height', $('.bookImage1').eq(this.data.bookIndex).innerHeight());
          $("mat-dialog-container").css('border-radius', '20px');
          $('.lastChild').css('height', $('.bookImage1').eq(this.data.bookIndex).innerHeight());
          $('.page').css('height', $('.bookImage1').eq(this.data.bookIndex).innerHeight());
          $('.nextPage').css("top", (($('.bookImage1').eq(this.data.bookIndex).innerHeight()/2)-50));

          setTimeout(() => {
              $('.bookTxt').css("font-size", $('mat-dialog-container').innerWidth()*0.0185);
          }, 100);
      }, 50);
  }

  // 離開檢視詢價單頁面
  leaveDialog() {
    $('.closeIcon').addClass('active');
    $('.bookNormalStyle').eq(this.data.bookIndex).css('width', $(".cdk-overlay-pane").innerHeight()+'px');
    $('.page1').eq(this.data.bookIndex).css('width', ($(".cdk-overlay-pane").innerHeight()/2)+'px');
    $('.page2').eq(this.data.bookIndex).css('width', ($(".cdk-overlay-pane").innerHeight()/2)+'px');
    $('.bookList').eq(this.data.bookIndex).css('visibility', 'visible');
    setTimeout(() => {
      $('.closeIcon').removeClass('active');
      $('.cdk-overlay-container').css({
        "z-index": 1000
      })
      this.dialogRef.close('');
    }, 300);
  }

  // 換頁(資料切換)
  nextPage(num) {
    this.pageIndex += num;

    if(this.pageIndex < -1) {
      this.pageIndex = 0;
    }
    else if(this.pageIndex > this.bookData.length-1) {
      this.pageIndex = this.bookData.length-1;
    }

    if(num > 0) {     // 下一個
      $('.listChild'+this.pageIndex).addClass('active');
    }
    else if(num < 0) {      // 上一個
      $('.listChild'+(this.pageIndex+1)).removeClass('active');
    }
  }
}
