<form (ngSubmit)="onSubmit(accountForm)" [formGroup]="accountForm">
  <select class="selectMargin" formControlName="type"  *ngIf="subTitleTab == 0">
    <option value="1">新眺帳號</option>
    <option value="2">團隊帳號</option>
  </select><br>
  <div class="area_con" *ngIf="subTitleTab == 0">
    <div class="itemStyle">
      <div class="alignRight" style="padding: 10px;">
        <input placeholder="帳號" class="inputSize" type="text" formControlName="acc"><br><br>
        <input placeholder="密碼" class="inputSize" type="text" formControlName="pass"><br><br>
        <input placeholder="確認密碼" class="inputSize" type="text" formControlName="passRe"><br><br>
        <button class="backBtnStyle btnDanger btnSpace" (click)="saveData()">送出</button>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 1">
    <div class="itemStyle" *ngFor="let data of dataArr; let i = index;">
      <div class="extendTitle" (click)="extendTab(i)">
        <h5 class="titleName">{{data}}</h5>
        <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
        <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
      </div>
      <div class="extendContent">
        <input placeholder="帳號" class="inputSize" type="text" formControlName="acc"><br><br>
        <input placeholder="密碼" class="inputSize" type="text" formControlName="pass" *ngIf="modify"><br *ngIf="modify"><br *ngIf="modify">
        <input placeholder="確認密碼" class="inputSize" type="text" formControlName="passRe" *ngIf="modify"><br *ngIf="modify"><br *ngIf="modify">
        <div class="alignRight">
          <button class="backBtnStyle btnWarn" *ngIf="!modify" (click)="modifyData()">修改密碼</button>
          <button class="backBtnStyle btnWarn" *ngIf="modify" (click)="cancel()">取消</button>
          <button class="backBtnStyle btnDanger btnSpace" *ngIf="modify" (click)="saveData()">送出</button>
        </div>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 2">
    <div class="itemStyle" *ngFor="let data of dataArr; let i = index;">
      <table class="table_delete">
        <tr>
          <td>
            <div class="extendTitle">
              <h5 class="titleName">{{data}}</h5>
            </div>
          </td>
          <td class="td_delete">
            <div class="alignRight inline_style">
              <button class="backBtnStyle btnDanger btnSpace deleteBtn_noPad" (click)="deleteData(i)">刪除</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>