<mat-dialog-content (window:resize)="resetSize()">
  <div class="closeIcon" (click)='leaveDialog()'><img src="assets/pop_close_btn.png"></div>
  <div class="nextPage">
    <div class="rightDiv" *ngIf="videoIndex != videoData.length-1">
      <i class="fas fa-angle-right right1"></i>
      <i class="fas fa-arrow-right right2" (click)="nextPage(1)"></i>
    </div>
    <div class="rightDiv" *ngIf="videoIndex == videoData.length-1"></div>
    <div class="leftDiv" *ngIf="videoIndex != 0">
      <i class="fas fa-angle-left left1"></i>
      <i class="fas fa-arrow-left left2" (click)="nextPage(-1)"></i>
    </div>
    <div class="leftDiv" *ngIf="videoIndex == 0"></div>
  </div>
  <div align="center">
    <h4 class="title">
      <a target="_blank" href="{{videoData[videoIndex].fblinkurl}}">
        <span *ngIf="currentLang == 'en-GB'">{{videoData[videoIndex].entitle}}</span>
        <span *ngIf="currentLang == 'zh-TW'">{{videoData[videoIndex].chtitle}}</span>
      </a>
    </h4>
  </div>
  <div class="videoContainer">
    <iframe id="iframeFullVideo" class="iframeFullStyle" style="border:none;overflow:hidden;" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
    <!--<iframe id="iframeVideo" class="iframeStyle" style="border:none;overflow:hidden;width: 100%;" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameborder="0" allowfullscreen *ngIf="videoType == 1"></iframe>-->
  </div>
</mat-dialog-content>