import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as globalVar from "../../globalVar";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  signIn: FormGroup;

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit() {
    this.signIn = new FormGroup({
      account: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl('')
    })

    if(document.cookie.indexOf("accountHBWeb") != -1) {     // 如果曾經點選記住密碼，取出cookie
      let account;     // 帳號
      let password;      // 密碼

      this.signIn.get('remember').setValue(true);     // 勾選記住密碼

      account = document.cookie.split("accountHBWeb=")[1].split("; passwordHBWeb")[0];
      password = document.cookie.split("passwordHBWeb=")[1].split(";")[0];

      // 將取得的cookie資料塞入帳密欄位
      this.signIn.get('account').reset(account);
      this.signIn.get('password').reset(password);
    }
  }

  // 確認帳號密碼的正確性
  onSubmit(value){
    if(!this.signIn.invalid) {
      let formData = new FormData();    // 存客戶輸入的資料
      formData.append("account", this.signIn.value.account);
      formData.append("password", this.signIn.value.password);

      this.http.post(globalVar.httpShowURL+'Login', formData).subscribe(
        res => {
          if(res[0].Response == 1) {
            if(this.signIn.get('remember').value == true) {      // 設定帳密cookie
              this.setCookie(this.signIn.value.account, this.signIn.value.password, 'set');
            }
            else {
              this.setCookie(this.signIn.value.account, this.signIn.value.password, 'destory');
            }

            sessionStorage.setItem("account", res[0].Account);
            sessionStorage.setItem("accountType", res[0].type);
            this.router.navigate(['/BackManager']);
          }
          else if(res[0].Response == 3) {
            alert("帳密錯誤");
          }
        }
      );
    }
    else {
      alert("請輸入帳號、密碼")
    }
  }

  setCookie(account, password, type) {
    let expires = new Date();

    if(type == 'set') {     // 若有勾選記住，將帳密塞入cookie
      if(document.cookie != "") {     // 若原本有值，先移除
        document.cookie = "accountHBWeb="+account+";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie = "passwordHBWeb="+password+";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }

      expires.setTime(expires.getTime()+365*24*60*60*1000);
      document.cookie = "accountHBWeb="+account+";expires="+expires.toUTCString();
      document.cookie = "passwordHBWeb="+password+";expires="+expires.toUTCString();
    }
    else if(type == 'destory' && document.cookie != "") {      // 若無勾選，將帳密移除cookie
      document.cookie = "accountHBWeb="+account+";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "passwordHBWeb="+password+";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  }

  leave() {
      this.router.navigate(['/']);
      setTimeout(() => {
          window.location.reload();
      }, 1000);
  }

}
