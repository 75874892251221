import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { VideoDialogComponent } from "../video-dialog/video-dialog.component";
import * as globalVar from "../../globalVar";
import * as $ from "jquery";

@Component({
  selector: 'app-video-list-dialog',
  templateUrl: './video-list-dialog.component.html',
  styleUrls: ['./video-list-dialog.component.scss']
})
export class VideoListDialogComponent implements OnInit {

  tabIndex = 0;     // 使用者所點選的tab類型
  videoData = [];
  cardArr = [];     // 所有card的資料
  nowCardArr = [];      // 目前頁面的card資料
  pageIndex = 0;      // 目前是第幾頁
  pageDataSize;     // 每頁應呈現的card數量
  allPage;      // 總頁數
  imgPre = globalVar.httpGetImgURL;
  currentLang;      // 目前的語言

  // 計算card的寬高
  windowWidth;      // 寬
  windowHeight;     // 高
  size;     // card寬高(正方形)
  margin;     // card邊距

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<any>, private http: HttpClient, public translateService: TranslateService) {
      this.videoData = this.data.videoData;
      this.currentLang = this.translateService.store.currentLang;
  }

  ngOnInit() {
    this.tabIndex = this.data.drawerIndex;

    this.windowWidth = Number($('.card-deck').innerWidth())-36;      // 寬
    this.windowHeight = Number($(window).innerHeight())-200;     // 高

    if(this.windowWidth/this.windowHeight >= 1) {     // 寬大於高
        this.size =this. windowHeight/2;
        this.margin = 16/Math.floor(this.windowWidth/this.size);

        $('.card-deck').css({
            'width': this.size*Math.floor(this.windowWidth/this.size)+36
        });
    }
    else if(this.windowWidth/this.windowHeight < 1) {     // 高大於寬
        this.size = this.windowWidth/2;
        this.margin = 16/Math.floor(this.windowWidth/this.size);
    }

    $('.closeIcon').css('margin-top', $('.dialogTitle').innerHeight()+23+'px')

    // 上下一頁icon位置
    $('.nextPage').css("top", (Math.floor(this.windowHeight/this.size)*this.size)/2-50+"px");

    // 計算每頁應呈現幾個項目
    this.pageDataSize = Number(Math.floor(this.windowWidth/this.size)*Math.floor(this.windowHeight/this.size));

    this.cardArr = this.videoData;

    // 第一頁資料
    this.nowCardArr = this.cardArr.slice(0, this.pageDataSize);

    // 計算總頁數
    this.allPage = Math.ceil(this.cardArr.length/this.pageDataSize)-1;

    setTimeout(() => {
        $('.card').css({
            'width': this.size,
            'height': this.size,
            'margin': this.margin
        });

        // $('.titleStyle').css('font-size', this.size/9+'px');

        for(let i=0; i<this.videoData.length; i++) {
            document.getElementById("iframeVideo"+i).setAttribute("src", this.videoData[i].fblinkurl);
        }
    }, 100);
  }

  // 換頁(資料切換)
  nextPage(num) {
    this.pageIndex += num;

    if(this.pageIndex < 0) {
      this.pageIndex = 0;
    }
    else if(this.pageIndex > Math.ceil(this.cardArr.length/this.pageDataSize)) {
      this.pageIndex = Math.ceil(this.cardArr.length/this.pageDataSize)-1;
    }

    if(this.pageIndex*this.pageDataSize != 0) {
      this.nowCardArr = this.cardArr.slice(this.pageIndex*this.pageDataSize, (this.pageIndex+1)*this.pageDataSize);
    }
    else if(this.pageIndex*this.pageDataSize == 0) {
      this.nowCardArr = this.cardArr.slice(0, this.pageDataSize);
    }


    // if(this.nowCardArr.length < this.pageDataSize) {
    //   for(let i=this.nowCardArr.length; i< this.pageDataSize; i++) {
    //     this.nowCardArr.push(0);
    //   }
    // }

    setTimeout(() => {
      $('.card').css({
        'width': this.size,
        'height': this.size,
        'margin': this.margin
      });
    }, 100);
  }

  // 開啟video播放popout
  openVideoDialog(index) {
      let videoDialogComponent = this.dialog.open( VideoDialogComponent, {
          autoFocus: false,   // 不要預設一開始focus在某個input內
          disableClose: true,
          data:{    // 傳值
              'videoIndex': index,
              'videoData': this.videoData
          }
      });

      // 當dialog關閉，可接收回傳值(尚未設定)
      videoDialogComponent.afterClosed().subscribe(
          result => {
          }
      );
  }

  // 離開檢視詢價單頁面
  leaveDialog() {
    $('.closeIcon').addClass('active');
    setTimeout(() => {
        $('.closeIcon').removeClass('active');
        this.dialogRef.close('');
    }, 1000);
  }

}
