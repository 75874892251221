<mat-dialog-content>
  <div class="dialogTitle" align="center">
    <ul>
      <li class="tab" (click)="changeTab(0)">
        {{'works.technology' | translate}}<span class="underlineStyle"></span>
      </li>
      <li class="tab" (click)="changeTab(1)">
        {{'works.website' | translate}}<span class="underlineStyle"></span>
      </li>
      <li class="tab" (click)="changeTab(2)">
        {{'works.printAD' | translate}}<span class="underlineStyle"></span>
      </li>
      <li class="tab" (click)="changeTab(3)">
        {{'works.imc' | translate}}<span class="underlineStyle"></span>
      </li>
      <li class="tab" (click)="changeTab(4)">
        {{'works.video' | translate}}<span class="underlineStyle"></span>
      </li>
      <li class="tab" (click)="changeTab(5)">
        {{'works.music' | translate}}<span class="underlineStyle"></span>
      </li>
      <li class="tab" (click)="changeTab(6)">
        {{'works.other' | translate}}<span class="underlineStyle"></span>
      </li>
    </ul>
  </div>
  <div class="sameLine closeIcon" (click)='leaveDialog()'><img src="assets/pop_close_btn.png"></div>
  <div class="imageContainer">
    <div class="card-deck">
      <div class="card" align="center" *ngFor="let card of nowCardArr">
        <a target="_blank" href="{{card.linkurl}}">
          <div class="card-img-top imgDiv">
            <img class="cardImg" src="{{imgPre}}{{card.image}}" alt="" *ngIf="card != 0">
            <!--<img class="cardImg" src="assets/worksDemo.jpg" alt="" *ngIf="card == 0">-->
          </div>
          <div class="card-img-top hoverDiv" *ngIf="card != 0">
            <h2 class="titleStyle" *ngIf="currentLang == 'zh-TW'">{{card.chtitle}}</h2>
            <h2 class="titleStyle" *ngIf="currentLang == 'en-GB'">{{card.entitle}}</h2>
          </div>
        </a>
      </div>
    </div>
    <div class="nextPage" [ngStyle]="{'visibility':pageDataSize >= cardArr.length ? 'hidden' : 'visible' }">
      <div class="rightDiv">
        <i class="fas fa-angle-right right1" *ngIf="pageIndex < allPage"></i>
        <i class="fas fa-arrow-right right2" (click)="nextPage(1)" *ngIf="pageIndex < allPage"></i>
      </div>
      <!--<div class="rightDiv" *ngIf="pageIndex == allPage"></div>-->
      <div class="leftDiv">
        <i class="fas fa-angle-left left1" *ngIf="pageIndex != 0"></i>
        <i class="fas fa-arrow-left left2" (click)="nextPage(-1)" *ngIf="pageIndex != 0"></i>
      </div>
      <!--<div class="leftDiv" *ngIf="pageIndex == 0"></div>-->
    </div>
  </div>
</mat-dialog-content>
