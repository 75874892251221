import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import * as globalVar from "../../globalVar";

@Component({
  selector: 'app-step-text',
  templateUrl: './step-text.component.html',
  styleUrls: ['./step-text.component.css']
})
export class StepTextComponent implements OnInit, OnChanges {

  stepTextForm: FormGroup;
  dataArr = [];         // 資料集
  nowDataArr = [];         // 目前顯示的資料集
  extendIndex = -1;     // 要伸展開的項目
  modify = false;       // 是否修改資料

  @Input() subTitleTab;     // 新增、修改
  @Input() leftBarTab;      // 階段文字(0)
  @Output('loadingComponent') loadingComponent = new EventEmitter<number>();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  ngOnChanges(changeItems) {
      if(changeItems.subTitleTab) {
          if(this.subTitleTab == 0) {
              this.setValue(0);
          }
          else if(this.subTitleTab == 1) {
              this.extendIndex = -1;

              this.stepTextForm = new FormGroup({
                  language: new FormControl(1),
                  stepName: new FormControl(1),
                  title: new FormControl('', Validators.required),
                  content: new FormControl('', Validators.required)
              });
              this.searchData();
          }
      }
      else if(changeItems.leftBarTab && this.leftBarTab == 0) {
          this.setValue(0);
      }
  }

  // 搜尋資料
  searchData() {
      this.http.post(globalVar.httpShowURL+'infos?x=1', null).subscribe(
          (res:any) => {
              this.dataArr = res;
              this.langChange();
          }
      )
  }

  onSubmit(value) {

  }

  // 將項目伸展開
  extendTab(index) {
    this.modify = false;
    if(this.extendIndex == -1) {        // 目前沒開啟的項目，直接展開
        $('.extendContent').eq(index).addClass('active');
        this.extendIndex = index;
        this.setValue(index);
    }
    else if(this.extendIndex == index) {        // 點擊開啟的項目，關閉此項目
        $('.extendContent').eq(this.extendIndex).removeClass('active');
        this.extendIndex = -1;
    }
    else {        // 已有開啟項目，點擊非開啟的項目，項目一開一關
        $('.extendContent').eq(this.extendIndex).removeClass('active');
        $('.extendContent').eq(index).addClass('active');
        this.extendIndex = index;
        this.setValue(index);
    }
  }

  // 改變語言
  langChange() {
      let langName;
      if(this.stepTextForm.get('language').value == 1) {
          langName = 'CH';
      }
      else if(this.stepTextForm.get('language').value == 2) {
          langName = 'EN';
      }

      if(this.subTitleTab == 1 || this.subTitleTab == 2) {
          this.nowDataArr = this.dataArr[0][langName];
      }

  }

  // 設定表單欄位
  setValue(index) {
      if(this.subTitleTab == 0) {       // 新增
          this.stepTextForm = new FormGroup({
              language: new FormControl(1),
              stepName: new FormControl(1),
              title: new FormControl('', Validators.required),
              content: new FormControl('', Validators.required)
          });
      }
      else if(this.subTitleTab == 1) {       // 修改
          this.stepTextForm.get('title').reset({value: this.nowDataArr[index].title, disabled: true});
          this.stepTextForm.get('content').reset({value: this.nowDataArr[index].content, disabled: true});
      }
  }

  // 修改
  modifyData(index) {
      this.modify = true;
      this.stepTextForm.get('title').reset({value: this.nowDataArr[index].title, disabled: false}, Validators.required);
      this.stepTextForm.get('content').reset({value: this.nowDataArr[index].content, disabled: false}, Validators.required);
  }

  // 取消修改
  cancel(index) {
      this.stepTextForm.get('title').reset({value: this.nowDataArr[index].title, disabled: true});
      this.stepTextForm.get('content').reset({value: this.nowDataArr[index].content, disabled: true});
      this.modify = false;
  }

  // 送出修改
  saveData(index) {
      this.loadingComponent.emit(1);
      let formData = new FormData();
      if(index == -1) {
          formData.append('id', '');
          formData.append('type', this.stepTextForm.get('stepName').value);
      }
      else {
          formData.append('id', this.nowDataArr[index].id);
          formData.append('type', this.nowDataArr[index].type);
      }
      formData.append('title', this.stepTextForm.get('title').value);
      formData.append('content', this.stepTextForm.get('content').value);
      formData.append('language', this.stepTextForm.get('language').value);

      this.http.post(globalVar.httpBackURL+'x=1', formData).subscribe(
          (res:any) => {
              if(res[0].Response == 1) {
                  if(index == -1) {
                      this.stepTextForm.get('title').reset({value: '', disabled: false}, Validators.required);
                      this.stepTextForm.get('content').reset({value: '', disabled: false}, Validators.required);
                  }
                  else {
                      this.nowDataArr[index].title = this.stepTextForm.get('title').value;
                      this.nowDataArr[index].content = this.stepTextForm.get('content').value;
                      this.stepTextForm.get('title').reset({value: this.nowDataArr[index].title, disabled: true}, Validators.required);
                      this.stepTextForm.get('content').reset({value: this.nowDataArr[index].content, disabled: true}, Validators.required);
                      this.modify = false;
                  }

                  this.loadingComponent.emit(2);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
          }
      )
  }
}
