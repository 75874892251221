import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from "@angular/forms";
import { DrawerDialogComponent } from './dialog-view/drawer-dialog/drawer-dialog.component';
import { BookDialogComponent } from './dialog-view/book-dialog/book-dialog.component';
import { VideoDialogComponent } from "./dialog-view/video-dialog/video-dialog.component";
import { VideoListDialogComponent } from './dialog-view/video-list-dialog/video-list-dialog.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
  MatDialogModule,
  MatGridListModule,
  MatCardModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatButtonModule
} from "@angular/material";
import { LoginComponent } from './back/login/login.component';
import { ManagerComponent } from "./back/manager/manager.component";
import { StepTextComponent } from './back/step-text/step-text.component';
import { MemberComponent } from './back/member/member.component';
import { WorksComponent } from './back/works/works.component';
import { VideoComponent } from './back/video/video.component';
import { AccountComponent } from './back/account/account.component';

export function createTranslateHttpLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DrawerDialogComponent,
    BookDialogComponent,
    VideoDialogComponent,
    VideoListDialogComponent,
    LoginComponent,
    ManagerComponent,
    StepTextComponent,
    MemberComponent,
    WorksComponent,
    VideoComponent,
    AccountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateHttpLoader),
            deps: [HttpClient]
        }
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DrawerDialogComponent,
    BookDialogComponent,
    VideoDialogComponent,
    VideoListDialogComponent
  ]
})
export class AppModule { }
