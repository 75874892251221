import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import * as globalVar from "../../globalVar";
import * as $ from "jquery";

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit, OnChanges {

  memberForm: FormGroup;
  dataArr = [];         // 資料集
  nowDataArr = [];         // 目前顯示的資料集
  extendIndex = -1;     // 要伸展開的項目
  modify = false;       // 是否修改資料
  langName;     // 語言

  showImage = [0, 0];
  imageUrl = ["", ""];
  imageData = ["", ""];
  imageUrltemp = ["", ""];

  @Input() subTitleTab;     // 新增、修改
  @Input() leftBarTab;      // 成員圖片(1)
  @Output('loadingComponent') loadingComponent = new EventEmitter<number>();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  ngOnChanges(changeItems) {
      if(changeItems.subTitleTab) {
          if(this.subTitleTab == 0) {     // 新增
              this.setValue(0);
          }
          else if(this.subTitleTab == 1 || this.subTitleTab == 2) {     // 修改、刪除
              this.extendIndex = -1;

              this.memberForm = new FormGroup({
                  language: new FormControl('1', Validators.required),
                  jobtitle: new FormControl('', Validators.required),
                  contact: new FormControl('', Validators.required),
                  email: new FormControl('', Validators.required),
                  leftImage: new FormControl(''),
                  rightImage: new FormControl(''),
                  imageCheck0: new FormControl('', Validators.required),
                  imageCheck1: new FormControl('', Validators.required)
              });
              this.searchData();
          }
      }
      else if(changeItems.leftBarTab && this.leftBarTab == 1) {
          this.setValue(0);
      }
  }

  // 搜尋資料
  searchData() {
      this.http.post(globalVar.httpShowURL+'infos?x=2', null).subscribe(
          (res:any) => {
              this.dataArr = res;

              this.showImage = [0, 0];
              this.imageData = ["", ""];
              this.imageUrl = ["", ""];

              this.langChange();
          }
      )
  }

  // 改變語言
  langChange() {
      if(this.memberForm.get('language').value == 1) {
          this.langName = 'CH';
      }
      else if(this.memberForm.get('language').value == 2) {
          this.langName = 'EN';
      }

      if(this.subTitleTab == 1 || this.subTitleTab == 2) {
          this.nowDataArr = this.dataArr[0][this.langName];
      }
  }

  onSubmit(value) {

  }

  // 將項目伸展開
  extendTab(index) {
      this.modify = false;
      if(this.extendIndex == -1) {        // 目前沒開啟的項目，直接展開
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
      else if(this.extendIndex == index) {        // 點擊開啟的項目，關閉此項目
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          this.extendIndex = -1;
      }
      else {        // 已有開啟項目，點擊非開啟的項目，項目一開一關
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
  }

  // 設定表單欄位
  setValue(index) {
      this.imageData = ["", ""];
      this.imageUrl = ["", ""];
      if(this.subTitleTab == 0) {       // 新增
          this.memberForm = new FormGroup({
              language: new FormControl('1', Validators.required),
              jobtitle: new FormControl('', Validators.required),
              contact: new FormControl('', Validators.required),
              email: new FormControl('', Validators.required),
              leftImage: new FormControl(''),
              rightImage: new FormControl(''),
              imageCheck0: new FormControl('', Validators.required),
              imageCheck1: new FormControl('', Validators.required)
          });
          this.showImage = [0, 0];
      }
      else if(this.subTitleTab == 1 || this.subTitleTab == 2) {       // 修改、刪除
          this.memberForm.get('jobtitle').reset({value: this.nowDataArr[index].jobtitle, disabled: true});
          this.memberForm.get('contact').reset({value: this.nowDataArr[index].contact, disabled: true});
          this.memberForm.get('email').reset({value: this.nowDataArr[index].email, disabled: true});
          this.imageUrl = [globalVar.httpGetImgURL + this.nowDataArr[index].leftimage, globalVar.httpGetImgURL + this.nowDataArr[index].rightimage];
          this.showImage = [1, 1];
      }
  }

  // 修改
  modifyData(index) {
      this.modify = true;
      this.memberForm.get('jobtitle').reset({value: this.nowDataArr[index].jobtitle, disabled: false}, Validators.required);
      this.memberForm.get('contact').reset({value: this.nowDataArr[index].contact, disabled: false}, Validators.required);
      this.memberForm.get('email').reset({value: this.nowDataArr[index].email, disabled: false}, Validators.required);
  }

  // 取消修改
  cancel(index) {
      this.memberForm.get('jobtitle').reset({value: this.nowDataArr[index].jobtitle, disabled: true});
      this.memberForm.get('contact').reset({value: this.nowDataArr[index].contact, disabled: true});
      this.memberForm.get('email').reset({value: this.nowDataArr[index].email, disabled: true});
      this.modify = false;
  }

  // 新增圖片
  addImage(index, imgIndex) {
      if(imgIndex == 0) {
          this.imageUrltemp = ["", ""];
      }

      if(this.subTitleTab == 1) {       // 修改
          this.memberForm.get('imageCheck0').reset('1', Validators.required);
          this.memberForm.get('imageCheck1').reset('1', Validators.required);
      }

      if(!this.memberForm.invalid) {
          this.loadingComponent.emit(1);

          if(this.imageData[imgIndex] != '') {
              let imageFormData = new FormData();
              imageFormData.append("upload[]", this.imageData[imgIndex]);

              this.http.post(globalVar.httpImgURL+"AlbumUpload", imageFormData).subscribe(
                  (res:any) => {

                      this.imageUrltemp[imgIndex] = res[0].imageUrl;

                      if(index != -1) {     // 刪除舊圖片
                          this.deleteChangeImage(index, imgIndex);
                      }

                      if(imgIndex == 1) {
                          this.saveData(index, this.imageUrltemp);
                      }
                      else {
                          imgIndex++;
                          setTimeout(() => {
                              this.addImage(index, imgIndex);
                          }, 1500);
                      }
                  }
              );
          }
          else {      // 未改圖片
              if(imgIndex == 0) {
                  this.imageUrltemp[imgIndex] = this.nowDataArr[index].leftimage;

                  imgIndex++;
                  setTimeout(() => {
                      this.addImage(index, imgIndex);
                  }, 1500);
              }
              else if(imgIndex == 1) {
                  this.imageUrltemp[imgIndex] = this.nowDataArr[index].rightimage;
                  this.saveData(index, this.imageUrltemp);
              }
          }
      }
      else {
          alert("請確認欄位、圖片是否皆有填寫");
      }
  }

  // 刪除資料
  deleteChangeImage(index, imgIndex) {
    let formData = new FormData();
    if(imgIndex == 0) {
        formData.append('FileName', this.nowDataArr[index].leftimage);
    }
    else if(imgIndex == 1) {
        formData.append('FileName', this.nowDataArr[index].rightimage);
    }

    this.http.post(globalVar.httpImgURL+'DeleteFile', formData).subscribe();
  }

  // 送出修改
  saveData(index, imageUrl) {
      this.loadingComponent.emit(1);

      let formData = new FormData();
      if(this.subTitleTab == 0) {     // 新增
          formData.append('id', '');
      }
      else if(this.subTitleTab == 1) {      // 修改
          formData.append('id', this.nowDataArr[index].id);
      }
      formData.append('mark', '');
      formData.append('language', this.memberForm.get('language').value);
      formData.append('jobtitle', this.memberForm.get('jobtitle').value);
      formData.append('contact', this.memberForm.get('contact').value);
      formData.append('email', this.memberForm.get('email').value);
      formData.append('Leftimage', imageUrl[0]);
      formData.append('Rightimage', imageUrl[1]);

      this.http.post(globalVar.httpBackURL+'x=2', formData).subscribe(
          (res:any) => {
              if(res[0].Response == 1) {
                  if(this.subTitleTab == 0) {
                      this.memberForm.get('jobtitle').reset({value: '', disabled: false}, Validators.required);
                      this.memberForm.get('contact').reset({value: '', disabled: false}, Validators.required);
                      this.memberForm.get('email').reset({value: '', disabled: false}, Validators.required);
                      this.memberForm.get('leftImage').reset({value: '', disabled: false});
                      this.memberForm.get('rightImage').reset({value: '', disabled: false});
                      this.memberForm.get('imageCheck0').reset('', Validators.required);
                      this.memberForm.get('imageCheck1').reset('', Validators.required);
                      this.imageUrl = ["", ""];
                  }
                  else {
                      this.nowDataArr[index].jobtitle = this.memberForm.get('jobtitle').value;
                      this.nowDataArr[index].contact = this.memberForm.get('contact').value;
                      this.nowDataArr[index].email = this.memberForm.get('email').value;
                      this.nowDataArr[index].leftimage = imageUrl[0];
                      this.nowDataArr[index].rightimage = imageUrl[1];
                      this.memberForm.get('jobtitle').reset({value: this.nowDataArr[index].jobtitle, disabled: true}, Validators.required);
                      this.memberForm.get('contact').reset({value: this.nowDataArr[index].contact, disabled: true}, Validators.required);
                      this.memberForm.get('email').reset({value: this.nowDataArr[index].email, disabled: true}, Validators.required);
                      this.memberForm.get('leftImage').reset({value: '', disabled: false});
                      this.memberForm.get('rightImage').reset({value: '', disabled: false});
                      this.memberForm.get('imageCheck0').reset('', Validators.required);
                      this.memberForm.get('imageCheck1').reset('', Validators.required);
                      this.imageUrl = [globalVar.httpGetImgURL + imageUrl[0], globalVar.httpGetImgURL + imageUrl[1]];
                      this.modify = false;
                  }

                  this.loadingComponent.emit(2);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
          }
      )
  }

  // 刪除資料
  deleteImage(index) {
      if(confirm("是否確定刪除?")) {
          this.loadingComponent.emit(1);

          let formData = new FormData();
          formData.append('FileName', this.nowDataArr[index].leftimage+','+this.nowDataArr[index].rightimage);

          this.http.post(globalVar.httpImgURL+'DeleteFile', formData).subscribe(
              (res:any) => {
                  let count = 0;
                  for(let i=0; i<res.length; i++) {
                      if(res[i] == true) {
                          count++;
                      }
                  }
                  if(count == res.length) {
                      this.deleteData(index);
                  }
                  else {
                      this.loadingComponent.emit(3);
                  }
              }
          );
      }
  }

  // 刪除資料
  deleteData(index) {
      let formData = new FormData();
      formData.append('id', this.nowDataArr[index].id);

      this.http.post(globalVar.httpBackURL+'x=7', formData).subscribe(
          (res:any) => {
              if(res[0].Response == 1) {
                  this.nowDataArr.splice(index, 1);
                  this.dataArr[0][this.langName].splice(index, 1);
                  this.loadingComponent.emit(4);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
          }
      );
  }

  handleInput(images: any, index) {
      if(images.length != 0) {   // 至少一個圖檔
          let reader = new FileReader();
          reader.onload = (event: any) => {
              this.imageUrl[index] = event.target.result;    // 所有圖皆有小圖
          }
          reader.readAsDataURL(images[0]);

          this.imageData[index] = images[0];
          this.showImage[index] = 1;
          this.memberForm.get('imageCheck'+index).reset("1", Validators.required);
      }
      else {
          this.imageUrl[index] = "";
          this.imageData[index] = "";
          this.showImage[index] = 0;
          this.memberForm.get('imageCheck'+index).reset("", Validators.required);      // 圖片沒傳
      }
  }

}
