<mat-dialog-content>
  <div class="dialogTitle" align="center">

  </div>
  <div class="sameLine closeIcon" (click)='leaveDialog()'><img src="assets/pop_close_btn.png"></div>
  <div class="imageContainer">
    <div class="card-deck">
      <div class="card" align="center" *ngFor="let video of videoData; let i = index" (click)="openVideoDialog(i)">
        <div class="card-img-top imgDiv">
          <iframe id="iframeVideo{{i}}" class="iframeStyle" style="border:none;overflow:hidden;" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
        </div>
        <div class="card-img-top hoverDiv" *ngIf="card != 0">
          <h2 class="titleStyle" *ngIf="currentLang == 'zh-TW'">{{video.chtitle}}</h2>
          <h2 class="titleStyle" *ngIf="currentLang == 'en-GB'">{{video.entitle}}</h2>
        </div>
      </div>
    </div>
    <div class="nextPage" [ngStyle]="{'visibility':pageDataSize >= cardArr.length ? 'hidden' : 'visible' }">
      <div class="rightDiv">
        <i class="fas fa-angle-right right1" *ngIf="pageIndex < allPage"></i>
        <i class="fas fa-arrow-right right2" (click)="nextPage(1)" *ngIf="pageIndex < allPage"></i>
      </div>
      <!--<div class="rightDiv" *ngIf="pageIndex == allPage"></div>-->
      <div class="leftDiv">
        <i class="fas fa-angle-left left1" *ngIf="pageIndex != 0"></i>
        <i class="fas fa-arrow-left left2" (click)="nextPage(-1)" *ngIf="pageIndex != 0"></i>
      </div>
      <!--<div class="leftDiv" *ngIf="pageIndex == 0"></div>-->
    </div>
  </div>
</mat-dialog-content>