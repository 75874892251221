import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as $ from "jquery";

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {

  videoIndex = 0;        // 目前播放的影片index
  videoData = [];
  currentLang;      // 目前的語言
  videoType;        // 影片類型

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<any>, public translateService: TranslateService) {
      this.videoIndex = this.data.videoIndex;
      this.videoData = this.data.videoData;
      this.currentLang = this.translateService.store.currentLang;
  }

  ngOnInit() {
    this.resetSize();

    this.identifyVideo(this.videoIndex);
  }

  // 重置大小
  resetSize() {
      let videoWidth;     // dialog寬
      let videoHeight;     // dialog高

      videoWidth = $(window).innerWidth();

      if((videoWidth*0.8*0.56)+24 >= $(window).innerHeight()) {
          videoHeight = $(window).innerHeight()-120;
      }
      else {
          videoHeight = (videoWidth*0.8*0.56)+24;
      }

      $(".closeIcon").eq(1).css({
          'top': ($(window).innerHeight()-videoHeight)/2+34+"px",
          'right': ($(window).innerWidth()-((videoWidth*0.8)+60))/2+30+"px"
      });
      $(".nextPage").eq(1).css({
          'top': $(window).innerHeight()/2-50+"px",
          'right': ($(window).innerWidth()-((videoWidth*0.8)+60))/2+27+"px"
      });
      $(".cdk-overlay-pane").eq(1).css({
          'height': videoHeight-60+"px",
          'width': (videoWidth*0.8)+60+'px'
      });

      $('.mat-dialog-content').eq(1).css({
          height: $('.cdk-overlay-pane').eq(1).innerHeight(),
          maxHeight: $('.cdk-overlay-pane').eq(1).innerHeight()
      });

      setTimeout(() => {
          $('.cdk-overlay-container').eq(1).css({
              "z-index": 1000
          })
      }, 1000);

      $('#iframeFullVideo').css({
          height: $('.cdk-overlay-pane').eq(1).innerHeight()-80
      })
  }

  // 上下一個
  nextPage(num) {
    this.videoIndex += num;
    if(this.videoIndex < 0) {
      this.videoIndex = 0;
    }
    else if(this.videoIndex > this.videoData.length-1) {
      this.videoIndex = this.videoData.length-1;
    }

    this.identifyVideo(this.videoIndex);
  }

  // 離開dialog
  leaveDialog() {
    $('.closeIcon').eq(1).addClass('active');
    setTimeout(() => {
        $('.closeIcon').eq(1).removeClass('active');
        this.dialogRef.close('');
    }, 1000);
  }

  identifyVideo(index) {
      if(this.videoData[index].fblinkurl.indexOf('youtube') != -1) {
          if(this.videoData[index].fblinkurl.indexOf('embed') == -1) {
              let getUrlId = this.videoData[index].fblinkurl.split("watch?v=")[1];
              this.videoData[index].fblinkurl = "https://www.youtube.com/embed/" + getUrlId;
          }

          // $('.iframeStyle').css('height', '95%');
          $('.iframeStyle').css('height', '57%');
      }
      else if(this.videoData[index].fblinkurl.indexOf('vimeo') != -1) {
          // $('.iframeStyle').css('height', '95%');
          $('.iframeStyle').css('height', '57%');
      }
      else if(this.videoData[index].fblinkurl.indexOf('facebook') != -1) {
          // $('.iframeStyle').css('height', '100%');
          $('.iframeStyle').css('height', '57%');
      }
      document.getElementById("iframeFullVideo").setAttribute("src", this.videoData[index].fblinkurl);
  }


}
