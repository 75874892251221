<form (ngSubmit)="onSubmit(videoForm)" [formGroup]="videoForm">
  <div class="area_con" *ngIf="subTitleTab == 0">
    <div class="itemStyle">
      <div class="alignRight" style="padding: 10px;">
        <input placeholder="中文標題" class="inputSize" type="text" formControlName="chtitle"><br><br>
        <input placeholder="英文標題" class="inputSize" type="text" formControlName="entitle"><br><br>
        <input placeholder="fb連結" class="inputSize" type="text" formControlName="fblinkurl"><br><br>
        <button class="backBtnStyle btnDanger btnSpace" (click)="saveData(-1)">送出</button>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 1">
    <div class="itemStyle" *ngFor="let data of dataArr; let i = index;">
      <div class="extendTitle" (click)="extendTab(i)">
        <h5 class="titleName">{{data.chtitle}}</h5>
        <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
        <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
      </div>
      <div class="extendContent">
        <input placeholder="中文標題" class="inputSize" type="text" formControlName="chtitle"><br><br>
        <input placeholder="英文標題" class="inputSize" type="text" formControlName="entitle"><br><br>
        <input placeholder="fb連結" class="inputSize" type="text" formControlName="fblinkurl"><br><br>
        <div class="alignRight">
          <button class="backBtnStyle btnWarn" *ngIf="!modify" (click)="modifyData(i)">修改</button>
          <button class="backBtnStyle btnWarn" *ngIf="modify" (click)="cancel(i)">取消</button>
          <button class="backBtnStyle btnDanger btnSpace" *ngIf="modify" (click)="saveData(i)">送出</button>
        </div>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 2">
    <div class="itemStyle" *ngFor="let data of dataArr; let i = index;">
      <table class="table_delete">
        <tr>
          <td>
            <div class="extendTitle" (click)="extendTab(i)">
              <h5 class="titleName">{{data.chtitle}}</h5>
              <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
              <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
            </div>
            <div class="extendContent">
              <input placeholder="中文標題" class="inputSize" type="text" formControlName="chtitle"><br><br>
              <input placeholder="英文標題" class="inputSize" type="text" formControlName="entitle"><br><br>
              <input placeholder="fb連結" class="inputSize" type="text" formControlName="fblinkurl"><br><br>
            </div>
          </td>
          <td class="td_delete">
            <div class="alignRight inline_style">
              <button class="backBtnStyle btnDanger btnSpace deleteBtn_noPad" (click)="deleteData(i)">刪除</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>