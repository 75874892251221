import { Component, OnInit, HostListener } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DrawerDialogComponent } from "./dialog-view/drawer-dialog/drawer-dialog.component";
import { BookDialogComponent } from "./dialog-view/book-dialog/book-dialog.component";
import { VideoListDialogComponent } from "./dialog-view/video-list-dialog/video-list-dialog.component";
import { VideoDialogComponent } from "./dialog-view/video-dialog/video-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as $ from "jquery";
import * as globalVar from "./globalVar";
import * as anime from "animejs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "新眺創意實驗室";
  imageurl = "action_0001-compressor.png";
  count = 1;
  stopWait = false; // 在文字出現的頁面須等一下
  loaging = true;
  loadingTxt = 0;
  rocking = true;
  stageKeyframe = [1, 136, 250, 346, 476, 619, 676, 757];
  stageFlag = 0; // 目前接近哪一階段
  stageNo = 0;
  nowStage = 0; // 目前的階段
  langSW = true;
  runDown = true;
  targetFrame = 0;
  scrollPar = 0; // 上一次滾動時間]
  waterFlag = ""; // 影片播放紐(水上升:up、水下降:down)
  showVideoClick = false; // 第476張圖，需顯示影片點擊區塊
  showFivBoxClick = false; // 第346張圖，需顯示抽屜點擊區塊
  showFivBookClick = false; // 第250張圖，需顯示書本點擊區塊
  openDialog = false; // 是否有開啟dialog
  bookIndex = -1; // 是否開啟書本
  awardsIndex = 0; // awards順序
  awardsArr = []; // awards內容(兩兩一組)

  orignialTxt = []; // 存原文字(文字亂數動畫要換回來)
  changeTxt = -1; // 判斷是否要亂數改變文字

  audio = new Audio("assets/windSound.mp4");
  backView = false; // 是否為後台網址

  percentColors = [
    { pct: 1, color: { r: 241, g: 241, b: 237 } },
    { pct: 570, color: { r: 241, g: 241, b: 237 } },
    { pct: 617, color: { r: 51, g: 51, b: 51 } },
    { pct: 689, color: { r: 51, g: 51, b: 51 } },
    { pct: 757, color: { r: 241, g: 241, b: 237 } },
  ];

  // DB資料
  stepData = []; // 文字階段資料
  bookData = []; // 書本資料
  worksData = []; // 外部資料
  videoData = []; // 影片資料
  nowStepData = [];
  nowBookData = [];
  stepTitle = [];
  imgPre = globalVar.httpGetImgURL;

  nowHour; // 小時
  nowMinute; // 分
  helloName; // 打招呼
  scrollFlag = 1; // scroll文字切換

  constructor(
    private dialog: MatDialog,
    public translateService: TranslateService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // 設定語言
    this.translateService.addLangs(["zh-TW", "en-GB"]);
    this.translateService.setDefaultLang("en-GB");
    this.translateService.use("en-GB");

    this.searchDB();

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      window.location.href = globalVar.httpURL + "/index.html";
    } else {
      if (window.location.href.indexOf("Back") != -1) {
        this.backView = true;
        this.openDialog = true;
        this.loaging = false;
        this.router.navigate(["/BackLogin"]);
      } else {
        this.backView = false;
        setTimeout(() => {
          this.nextImage();
          this.setWindowSize("");
        }, 20);
      }
    }

    this.sayHello();
  }

  searchDB() {
    let langName;
    if (this.translateService.store.currentLang == "en-GB") {
      langName = "EN";
    } else if (this.translateService.store.currentLang == "zh-TW") {
      langName = "CH";
    }

    this.http
      .post(globalVar.httpShowURL + "infos?x=1", null)
      .subscribe((res: any) => {
        this.stepData = res;
        this.nowStepData = res[0][langName];
        this.stepTitle = [];

        for (let i = 0; i < 9; i++) {
          if (i == 0) {
            this.stepTitle.push(this.stepData[0]["EN"][i].title.split(" "));
          } else {
            this.stepTitle.push(this.stepData[0]["EN"][i].title.split(""));
          }
        }

        this.splitAwards();
      });
    this.http
      .post(globalVar.httpShowURL + "infos?x=2", null)
      .subscribe((res: any) => {
        this.bookData = res;
        this.nowBookData = res[0][langName];
      });
    this.http
      .post(globalVar.httpShowURL + "infos?x=3", null)
      .subscribe((res: any) => {
        this.videoData = res;
      });
  }

  // 調整螢幕大小
  setWindowSize(event) {
    let windowWidth;
    let windowHeight;

    if (event == "") {
      windowWidth = $(window).innerWidth();
      windowHeight = $(window).innerHeight();
    } else {
      windowWidth = event.target.innerWidth;
      windowHeight = event.target.innerHeight;
    }

    if (windowWidth / windowHeight >= 1.8) {
      document.getElementById("imageId").style.height = "auto";
      document.getElementById("imageId").style.width = "100%";
      // document.getElementById("imageId").style.height = String(windowHeight)+"px";
      // document.getElementById("imageId").style.width = "";
      // document.getElementById("imageId").style.left = "50%";

      document.getElementById("stage").style.height =
        String(windowHeight) + "px";
      document.getElementById("stage").style.left = "50%";
      document.getElementById("stage").style.marginTop = "";
      document.getElementById("stage").style.top = "";

      setTimeout(() => {
        // document.getElementById("imageId").style.marginLeft = "-" + Number(document.getElementById("imageId").offsetWidth)/2 + "px";

        document.getElementById("stage").style.width =
          document.getElementById("imageId").offsetWidth + "px";
        document.getElementById("stage").style.marginLeft =
          "-" + Number(document.getElementById("stage").offsetWidth) / 2 + "px";
      }, 1000);
    } else if (windowWidth / windowHeight <= 1.7) {
      document.getElementById("imageId").style.height = "100%";
      document.getElementById("imageId").style.width = "auto";
      // document.getElementById("imageId").style.width = String(windowWidth)+"px";
      // document.getElementById("imageId").style.height = "";
      // document.getElementById("imageId").style.top = "50%";
      // document.getElementById("imageId").style.marginLeft = "";
      // document.getElementById("imageId").style.left = "";

      document.getElementById("stage").style.width = String(windowWidth) + "px";
      document.getElementById("stage").style.top = "50%";
      document.getElementById("stage").style.marginLeft = "";
      document.getElementById("stage").style.left = "";
      setTimeout(() => {
        // document.getElementById("imageId").style.marginTop = "-" + Number(document.getElementById("imageId").offsetHeight)/2 + "px";

        document.getElementById("stage").style.height =
          document.getElementById("imageId").offsetHeight + "px";
        document.getElementById("stage").style.marginTop =
          "-" +
          Number(document.getElementById("imageId").offsetHeight) / 2 +
          "px";
      }, 1000);
    } else {
      document.getElementById("imageId").style.height = "100%";
      document.getElementById("imageId").style.width = "100%";
      // document.getElementById("imageId").style.width = String(windowWidth)+"px";
      // document.getElementById("imageId").style.height = String(windowHeight)+"px";
      // document.getElementById("imageId").style.marginTop = "";
      // document.getElementById("imageId").style.top = "";
      // document.getElementById("imageId").style.marginLeft = "";
      // document.getElementById("imageId").style.left = "";

      document.getElementById("stage").style.width = String(windowWidth) + "px";
      document.getElementById("stage").style.height =
        String(windowHeight) + "px";
      document.getElementById("stage").style.marginTop = "";
      document.getElementById("stage").style.top = "";
      document.getElementById("stage").style.marginLeft = "";
      document.getElementById("stage").style.left = "";
    }

    document.getElementById("imageId").style.left = "50%";
    document.getElementById("imageId").style.top = "50%";
    setTimeout(() => {
      document.getElementById("imageId").style.marginLeft =
        "-" + Number(document.getElementById("imageId").offsetWidth) / 2 + "px";
      document.getElementById("imageId").style.marginTop =
        "-" +
        Number(document.getElementById("imageId").offsetHeight) / 2 +
        "px";
    }, 1000);

    if (this.nowStage == 2) {
      // member階段
      this.setBookStyle();
    } else if (this.nowStage == 3) {
      // works階段
      $(".firstBox").css({
        width: document.getElementById("imageId").offsetWidth * 0.125 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.026 + "px",
      });
    } else if (this.nowStage == 6) {
      // awards階段
      $(".omgInput").css("font-size", $(".omgInput").height() / 2);
    }

    // 有區塊要顯示的階段
    if (this.nowStage == 3 || this.nowStage == 4 || this.nowStage == 6) {
      document.getElementById("matchImage").style.height =
        String(document.getElementById("imageId").offsetHeight) + "px";
      document.getElementById("matchImage").style.width =
        String(document.getElementById("imageId").offsetWidth) + "px";
    } else if (this.nowStage == 2) {
      document.getElementById("matchImage").style.height = "100vh";
      document.getElementById("matchImage").style.width = "100vw";
    }

    if (this.nowStage == 3) {
      this.setDrawerSize();
    } else if (this.nowStage == 4) {
      // 調整播放鈕以及水流
      this.setVideoSize();
    }

    if (this.count == 676) {
      this.setSearchmanSize(676);
    } else if (this.count == 688) {
      this.setOMGSize();
      this.setSearchmanSize(688);
    }

    this.TxtAnimation(this.stageFlag);
  }

  onLangChange(event: any) {
    this.langSW = !this.langSW;

    if (this.langSW) {
      this.translateService.use("en-GB");
      this.nowStepData = this.stepData[0]["EN"];
      this.nowBookData = this.bookData[0]["EN"];
    } else {
      this.translateService.use("zh-TW");
      this.nowStepData = this.stepData[0]["CH"];
      this.nowBookData = this.bookData[0]["CH"];
    }

    this.splitAwards();
  }

  // 選擇哪種問好
  sayHello() {
    this.nowHour = new Date().getHours();
    this.nowMinute = new Date().getMinutes();

    if (this.nowHour >= 9 && this.nowHour < 12) {
      this.helloName = 1; // 早安
    } else if (this.nowHour >= 12 && this.nowHour < 18) {
      this.helloName = 2; // 午安
    } else {
      this.helloName = 3; // 晚安
    }
  }

  getColorForPercentage(imagePage) {
    let colorIndex;
    if (imagePage >= 1 && imagePage <= 570) {
      colorIndex = 0;
    } else if (imagePage > 500 && imagePage <= 617) {
      colorIndex = 1;
    } else if (imagePage > 617 && imagePage <= 689) {
      colorIndex = 2;
    } else if (imagePage > 689 && imagePage <= 757) {
      colorIndex = 3;
    }

    let lower = this.percentColors[colorIndex];
    let upper = this.percentColors[colorIndex + 1];
    let range = upper.pct - lower.pct;
    let rangePct = (imagePage - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    let color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };

    $("body").css(
      "background-color",
      "rgb(" + [color.r, color.g, color.b].join(",") + ")"
    );
  }

  // loading功能
  nextImage() {
    if (this.loaging) {
      this.loadingAnimation();
    } else {
      this.count = this.targetFrame;

      if (this.count >= 0 && this.count <= 20) {
        this.stageFlag = 0;
      } else if (this.count >= 116 && this.count <= 156) {
        this.stageFlag = 1;
      } else if (this.count >= 230 && this.count <= 270) {
        this.stageFlag = 2;
      } else if (this.count >= 326 && this.count <= 366) {
        this.stageFlag = 3;
      } else if (this.count >= 456 && this.count <= 496) {
        this.stageFlag = 4;
      } else if (this.count >= 599 && this.count <= 639) {
        this.stageFlag = 5;
      } else if (this.count >= 656 && this.count <= 696) {
        this.stageFlag = 6;
      } else if (this.count >= 737) {
        this.stageFlag = 7;
      } else {
        this.stageFlag = -1;
        this.changeTxt = -1;
        this.orignialTxt = [];
      }

      this.loadImage(this.targetFrame);
      this.checkInStage(this.targetFrame);

      if (this.targetFrame == 346) {
        this.showFivBoxClick = true;
      }
    }
  }

  // 設定研究員大小&位置
  setSearchmanSize(index) {
    let rangeValue = 0;
    if ($(window).innerWidth() / $(window).innerHeight() >= 1.75) {
      // width:100%
      rangeValue =
        (document.getElementById("imageId").offsetHeight -
          $(window).innerHeight()) /
        2;
    }

    if (index == 676) {
      setTimeout(() => {
        $(".searchManArrow").css({
          // width: document.getElementById("imageId").offsetWidth * 0.045 + 'px',
          marginTop:
            "-" +
            (document.getElementById("imageId").offsetWidth * 0.19 -
              rangeValue) +
            "px",
          marginLeft:
            "-" + document.getElementById("imageId").offsetWidth * 0.23 + "px",
        });
        $(".searchMan").css({
          width: document.getElementById("imageId").offsetWidth * 0.18 + "px",
          height: document.getElementById("imageId").offsetWidth * 0.23 + "px",
          marginTop:
            document.getElementById("imageId").offsetWidth * 0.134 -
            rangeValue +
            "px",
          marginLeft:
            "-" + document.getElementById("imageId").offsetWidth * 0.65 + "px",
        });
      });
    } else if (index == 688) {
      setTimeout(() => {
        $(".omgArrow").css({
          marginTop:
            document.getElementById("imageId").offsetWidth *
              0.15 *
              0.633 *
              0.5 -
            24 +
            "px",
          marginLeft:
            document.getElementById("imageId").offsetWidth * 0.15 + 10 + "px",
        });
      });
    }
  }

  // 設定OMG input 大小&位置
  setOMGSize() {
    let rangeValue = 0;
    if ($(window).innerWidth() / $(window).innerHeight() >= 1.75) {
      // width:100%
      rangeValue =
        (document.getElementById("imageId").offsetHeight -
          $(window).innerHeight()) /
        2;
    }

    setTimeout(() => {
      $(".omgTxt").css({
        width: document.getElementById("imageId").offsetWidth * 0.15 + "px",
        height:
          document.getElementById("imageId").offsetWidth * 0.15 * 0.633 + "px",
        marginTop:
          document.getElementById("imageId").offsetWidth * 0.14 -
          rangeValue +
          "px",
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.75 + "px",
      });
    }, 50);
  }

  // 抽屜大小&位置
  setDrawerSize() {
    let rangeValue = 0;
    if ($(window).innerWidth() / $(window).innerHeight() >= 1.75) {
      // width:100%
      rangeValue =
        (document.getElementById("imageId").offsetHeight -
          $(window).innerHeight()) /
        2;
    }
    setTimeout(() => {
      $(".firstBox").css({
        width: document.getElementById("imageId").offsetWidth * 0.125 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.026 + "px",
        marginTop:
          document.getElementById("imageId").offsetWidth * 0.195 -
          rangeValue +
          "px",
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.28 + "px",
      });
      $(".secondBox").css({
        width: document.getElementById("imageId").offsetWidth * 0.11 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.023 + "px",
        marginTop: document.getElementById("imageId").offsetWidth * 0.02 + "px",
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.27 + "px",
      });
      $(".thirdBox").css({
        width: document.getElementById("imageId").offsetWidth * 0.115 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.024 + "px",
        marginTop:
          document.getElementById("imageId").offsetWidth * 0.026 + "px",
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.13 + "px",
      });
      $(".fourthBox").css({
        width: document.getElementById("imageId").offsetWidth * 0.1 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.023 + "px",
        marginTop:
          document.getElementById("imageId").offsetWidth * 0.026 + "px",
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.14 + "px",
      });
      $(".fifthBox").css({
        width: document.getElementById("imageId").offsetWidth * 0.112 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.025 + "px",
        marginTop:
          document.getElementById("imageId").offsetWidth * 0.025 + "px",
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.13 + "px",
      });
    });
  }

  loadingAnimation() {
    let index = 0;
    let pct = 1;

    let loadingInterval = window.setInterval(() => {
      this.loadingTxt = pct;
      if ((pct + 1) % 20 == 0) {
        $(".loadingPage .stairs_box ul")
          .children()
          .eq(4 - index)
          .addClass("active");
        index++;
      }

      if (index > 4) {
        window.clearInterval(loadingInterval);
        this.count = 1;
        this.loadImage(this.count);
        this.loaging = false;
        this.rocking = false;
      }

      pct++;
    }, 50);
  }

  // 讀圖功能
  loadImage(sn) {
    // if (!this.loaging) {
    if (sn > 571 && sn < 717) {
      $(".matchImage").addClass("hasbg");
      $(".matchImage").css({
        height: String(document.getElementById("imageId").offsetHeight) + "px",
        width: String(document.getElementById("imageId").offsetWidth) + "px",
      });
      let bgIndex = Math.ceil((((sn - 571) % 24) * 2) / 3);
      // let bgIndex = (sn-571)%16;
      if (bgIndex == 0) {
        bgIndex = 16;
      }
      if (bgIndex < 10) {
        $(".matchImage").css(
          "background-image",
          "url(assets/smallStar/star0" + bgIndex + ".png)"
        );
      } else {
        $(".matchImage").css(
          "background-image",
          "url(assets/smallStar/star" + bgIndex + ".png)"
        );
      }

      $(".letter").css("color", "#fff");
      $(".desc").css("color", "#fff");
      $(".scrollTxt").css("color", "#fff");
      $(".scrollIcon").css("color", "#fff");
      $(".stairs_box .box_left").css("background-color", "#fff");
      $(".stairs_box .box_right").css("background-color", "#fff");
      $(".stairs_box.s li").css("background-color", "#fff");
      $(".switch_text").css("color", "#000");
      $(".scrollArrowColorV").css("color", "#efd846");
      $(".scrollArrowColorH").css("color", "#efd846");
      $(".share_Btn a").addClass("active");
      $(".language").addClass("active");

      $(".scrollArrowColorV").removeClass("active2");
      $(".scrollArrowColorH").removeClass("active2");
      $(".scrollArrowColorV").addClass("active1");
      $(".scrollArrowColorH").addClass("active1");
    } else {
      $(".matchImage").css("background-image", "");
      $(".matchImage").removeClass("hasbg");
      $(".letter").css("color", "#000");
      $(".desc").css("color", "#000");
      $(".scrollTxt").css("color", "#000");
      $(".scrollIcon").css("color", "#434343");
      $(".stairs_box .box_left").css("background-color", "#000");
      $(".stairs_box .box_right").css("background-color", "#000");
      $(".stairs_box.s li").css("background-color", "#000");
      $(".switch_text").css("color", "#fff");
      $(".scrollArrowColorV").css("color", "#fff");
      $(".scrollArrowColorH").css("color", "#fff");
      $(".share_Btn a").removeClass("active");
      $(".language").removeClass("active");

      $(".scrollArrowColorV").removeClass("active1");
      $(".scrollArrowColorH").removeClass("active1");
      $(".scrollArrowColorV").addClass("active2");
      $(".scrollArrowColorH").addClass("active2");
    }
    // }
    const str = this.paddingLeft(sn, 4);

    this.getColorForPercentage(sn); // 產生圖的底色
    $(".scrollIcon").css("transform", "rotate(0deg)");

    if (sn == 250) {
      // 產生梯子拍動的gif
      this.imageurl = "stair.gif"; // 產生研究員換腳gif

      this.audio.play();
      this.audio.loop = true;
      document.getElementById("matchImage").style.height =
        String(document.getElementById("imageId").offsetHeight) + "px";
      document.getElementById("matchImage").style.width =
        String(document.getElementById("imageId").offsetWidth) + "px";
      this.showFivBookClick = true;
      this.showVideoClick = false;

      this.setBookSize();
    } else if (sn == 346) {
      this.setDrawerSize();
      this.showFivBookClick = false;
      this.showVideoClick = false;
      this.imageurl = "action_" + str + "-compressor.png";
    } else if (sn == 476) {
      this.imageurl = "foot.gif"; // 產生研究員換腳gif
      document.getElementById("matchImage").style.height =
        String(document.getElementById("imageId").offsetHeight) + "px";
      document.getElementById("matchImage").style.width =
        String(document.getElementById("imageId").offsetWidth) + "px";
      this.showVideoClick = true;
      this.showFivBookClick = false;
      $(".scrollIcon").css("transform", "rotate(180deg)");

      this.setVideoSize();
    } else if (sn == 676) {
      this.showFivBookClick = false;
      this.showVideoClick = false;
      document.getElementById("matchImage").style.height =
        String(document.getElementById("imageId").offsetHeight) + "px";
      document.getElementById("matchImage").style.width =
        String(document.getElementById("imageId").offsetWidth) + "px";
      this.imageurl = "action_" + str + "-compressor.png";

      this.setSearchmanSize(676);
    } else if (sn == 688) {
      this.setOMGSize();
      this.showFivBookClick = false;
      this.showVideoClick = false;
      this.imageurl = "action_" + str + "-compressor.png";

      this.setSearchmanSize(688);
    } else {
      if (this.showFivBookClick) {
        // 書本消失動畫
        this.bookFadeAway();
      }
      this.audio.pause();
      this.showVideoClick = false;
      this.imageurl = "action_" + str + "-compressor.png";
    }

    if (sn == 1) {
      this.checkInStage(1);
    }

    if (sn < 676 || sn == 757) {
      $(".scrollBtn_con").css("opacity", "1");
    } else {
      $(".scrollBtn_con").css("opacity", "0");
    }
  }

  // 設定影片區塊大小
  setVideoSize() {
    let rangeValue = 0;
    if ($(window).innerWidth() / $(window).innerHeight() >= 1.75) {
      // width:100%
      rangeValue =
        (document.getElementById("imageId").offsetHeight -
          $(window).innerHeight()) /
        2;
    }
    setTimeout(() => {
      $(".videoDownBox").css({
        marginLeft:
          "-" + document.getElementById("imageId").offsetWidth * 0.335 + "px",
        marginTop:
          document.getElementById("imageId").offsetWidth * 0.22 -
          rangeValue +
          "px",
        width: document.getElementById("imageId").offsetWidth * 0.292 + "px",
        padding: document.getElementById("imageId").offsetWidth * 0.08 + "px",
      });

      $(".tv_style").css({
        marginLeft: "-" + $(".videoDownBox").innerWidth() / 2 + "px",
        marginTop: "-" + $(".videoDownBox").innerHeight() / 2 + "px",
      });

      $("#videoImage").css({
        width: $(".videoDownBox").innerHeight() / 4 + "px",
        height: $(".videoDownBox").innerHeight() / 4 + "px",
        marginLeft:
          "-" +
          (document.getElementById("imageId").offsetWidth * 0.21 -
            $(".videoDownBox").innerHeight() / 8) +
          "px",
        marginTop:
          "-" +
          (document.getElementById("imageId").offsetWidth * 0.115 -
            $(".videoDownBox").innerHeight() / 8) +
          "px",
      });

      // $('#playDiv').css({
      //     'width': $('#videoImage').innerWidth()+"px",
      //     'height': $('#videoImage').innerHeight()+"px",
      //     'margin-top': ($('.videoDownBox').innerHeight()/4)+"px",
      // });
      //
      // $('.waterImage').css({
      //     'width': ($('#videoImage').innerWidth()*40)+"px",
      //     'height': ($('#videoImage').innerHeight()+10)+"px",
      //     'margin-right': (0-$('#videoImage').innerWidth())+"px",
      //     'margin-top': "0px",
      //     'top': $('#videoImage').innerHeight()+"px"
      // });
    }, 100);
  }

  paddingLeft(str, lenght) {
    if (str.length >= lenght) {
      return str;
    } else {
      return this.paddingLeft("0" + str, lenght);
    }
  }

  // 監聽滾輪事件
  @HostListener("document:mousewheel", ["$event"])
  onMousewheel(event) {
    if (event.timeStamp - this.scrollPar > 1000 && this.backView == false) {
      // stopWait：出現文字的地方，停下3秒
      // event.cancelable：滾動的第1個為true，單次滑滾輪只算第一下
      if (!this.stopWait && !this.openDialog) {
        this.scrollPar = event.timeStamp;
        let end = 0;
        if (event.wheelDelta > 0) {
          end = this.count - 10;
          if (end < 1) {
            end = 1;
          }

          if (end >= 0 && end <= 20) {
            this.stageFlag = 0;
          } else if (end >= 116 && end <= 156) {
            this.stageFlag = 1;
          } else if (end >= 230 && end <= 270) {
            this.stageFlag = 2;
          } else if (end >= 326 && end <= 366) {
            this.stageFlag = 3;
          } else if (end >= 456 && end <= 496) {
            this.stageFlag = 4;
          } else if (end >= 599 && end <= 639) {
            this.stageFlag = 5;
          } else if (end >= 656 && end <= 696) {
            this.stageFlag = 6;
          } else {
            this.stageFlag = -1;
            this.changeTxt = -1;
            this.orignialTxt = [];
          }

          if (this.count == 757) {
            // 到底後往前滑，回到OMG輸入階段
            end = 688;
          }

          if (
            this.stageFlag != -1 &&
            this.stageFlag != 6 &&
            this.stageKeyframe.indexOf(this.count) == -1
          ) {
            // && (this.count<571 || this.count>599)
            $("#txt").css("visibility", "hidden");

            setTimeout(() => {
              $("#txt").css("visibility", "visible");
            }, 1000);
          }

          this.imageRunDown(this.count, end);
        } else {
          if (this.count < 676) {
            // 第676後禁止滑動
            end = this.count + 10;
            if (end > 757) {
              end = 757;
            }

            if (end >= 0 && end <= 20) {
              this.stageFlag = 0;
            } else if (end >= 116 && end <= 156) {
              this.stageFlag = 1;
            } else if (end >= 230 && end <= 270) {
              this.stageFlag = 2;
            } else if (end >= 326 && end <= 366) {
              this.stageFlag = 3;
            } else if (end >= 456 && end <= 496) {
              this.stageFlag = 4;
            } else if (end >= 599 && end <= 639) {
              this.stageFlag = 5;
            } else if (end >= 656 && end <= 696) {
              this.stageFlag = 6;
            } else {
              this.stageFlag = -1;
              this.changeTxt = -1;
              this.orignialTxt = [];
            }

            if (
              this.stageFlag != -1 &&
              this.stageKeyframe.indexOf(this.count) == -1
            ) {
              //  && (this.count<571 || this.count>599)
              $("#txt").css("visibility", "hidden");

              setTimeout(() => {
                $("#txt").css("visibility", "visible");
              }, 1000);
            }

            this.imageRunUp(this.count, end);
          }
        }
        this.rocking = true;
      }
    }
  }

  // @HostListener('touchstart', ['$event'])
  // fingerMoveStart(event) {
  //     console.log(event.touches[0].pageX, event.touches[0].pageY);
  // }
  //
  // @HostListener('touchend', ['$event'])
  // fingerMoveEnd(event) {
  //     console.log(event.changedTouches[0].pageX, event.changedTouches[0].pageY);
  // }

  go(frame: number) {
    this.closeMenu();
    $("#stage").hide();
    if (frame > this.count) {
      // this.count = this.count + 5;
      this.runDown = true;
    } else {
      // this.count = this.count - 5;
      this.runDown = false;
    }
    this.stopWait = false;
    this.openDialog = false;
    this.targetFrame = frame;
    this.nextImage();
    this.setWindowSize("");

    if (frame == 250) {
      // 開啟風聲
      this.audio.play();
      this.audio.loop = true;
    } else {
      // 關閉風聲
      this.audio.pause();
    }
  }

  // 圖片往前
  imageRunUp(start, end) {
    const interval = window.setInterval(() => {
      start++;

      if (start > end) {
        this.rocking = false;
        this.stopWait = false;
        if (
          !(
            end < 20 ||
            (end > 116 && end < 156) ||
            (end > 230 && end < 270) ||
            (end > 326 && end < 366) ||
            (end > 456 && end < 496) ||
            (end > 570 && end < 639) ||
            (end > 656 && end < 696)
          )
        ) {
          // 不再此範圍中，重置文字亂書動畫
          this.changeTxt = -1;
          this.orignialTxt = [];
        }
        window.clearInterval(interval);
        this.checkInStage(end);
      } else {
        this.count = start;
        this.loadImage(start);
        this.checkInStage(start);

        if (this.stageKeyframe.indexOf(this.count) != -1) {
          // 須出現文字的地方，停下
          this.stopWait = true;
          window.clearInterval(interval);

          setTimeout(() => {
            this.stopWait = false;
          }, 2000);
        }

        if (start == 737) {
          // 最後一階段~
          this.stageFlag = 7;
        }

        // 不要在關鍵區間逗留
        if (
          end < this.stageKeyframe[this.stageFlag] + 20 &&
          end >= this.stageKeyframe[this.stageFlag]
        ) {
          if (this.stageFlag != 6) {
            // omg階段不要走那麼多
            end = this.stageKeyframe[this.stageFlag] + 22;
          }
          this.stopWait = true;
        } else if (
          end < this.stageKeyframe[this.stageFlag] &&
          end > this.stageKeyframe[this.stageFlag] - 20
        ) {
          end = this.stageKeyframe[this.stageFlag];
          this.stopWait = true;
        }

        if (start == 346) {
          this.showFivBoxClick = true;
          document.getElementById("matchImage").style.height =
            String(document.getElementById("imageId").offsetHeight) + "px";
          document.getElementById("matchImage").style.width =
            String(document.getElementById("imageId").offsetWidth) + "px";
        } else {
          this.showFivBoxClick = false;
        }
      }
    }, 100);
  }
  // 圖片倒退
  imageRunDown(start, end) {
    const interval = window.setInterval(() => {
      start--;

      if (start < end) {
        this.rocking = false;
        this.stopWait = false;
        if (
          !(
            end < 20 ||
            (end > 116 && end < 156) ||
            (end > 230 && end < 270) ||
            (end > 326 && end < 366) ||
            (end > 456 && end < 496) ||
            (end > 570 && end < 639) ||
            (end > 656 && end < 696)
          )
        ) {
          // 不再此範圍中，重置文字亂書動畫
          this.changeTxt = -1;
          this.orignialTxt = [];
        }
        window.clearInterval(interval);
        this.checkInStage(end);
      } else {
        this.count = start;
        this.loadImage(start);
        this.checkInStage(start);

        if (this.stageKeyframe.indexOf(this.count) != -1) {
          this.stopWait = true;
          window.clearInterval(interval);

          setTimeout(() => {
            this.stopWait = false;
          }, 2000);
        }

        if (start < 698 && start > 686 && this.stageFlag != 6) {
          this.stageFlag = 6;
          $("#txt").css("visibility", "hidden");

          setTimeout(() => {
            $("#txt").css("visibility", "visible");
          }, 500);
        }

        // 不要在關鍵區間逗留
        if (
          end < this.stageKeyframe[this.stageFlag] + 20 &&
          end > this.stageKeyframe[this.stageFlag]
        ) {
          if (end != 688) {
            end = this.stageKeyframe[this.stageFlag];
          }
          this.stopWait = true;
        } else if (
          end <= this.stageKeyframe[this.stageFlag] &&
          end > this.stageKeyframe[this.stageFlag] - 20
        ) {
          end = this.stageKeyframe[this.stageFlag] - 22;
          this.stopWait = true;
        }

        if (start == 346) {
          this.showFivBoxClick = true;
          document.getElementById("matchImage").style.height =
            String(document.getElementById("imageId").offsetHeight) + "px";
          document.getElementById("matchImage").style.width =
            String(document.getElementById("imageId").offsetWidth) + "px";
        } else {
          this.showFivBoxClick = false;
        }
      }
    }, 100);
  }

  // 檢查是否已進入單元
  checkInStage(keyframe: number) {
    this.TxtAnimation(this.stageFlag);
    const inSatge = this.stageKeyframe.indexOf(keyframe);
    if (inSatge !== -1) {
      $("#stage").show();
      this.stageNo = inSatge;
      this.nowStage = inSatge;

      if (this.stageNo == 3) {
        // 若非works不呈現點擊區塊
        document.getElementById("matchImage").style.height =
          String(document.getElementById("imageId").offsetHeight) + "px";
        document.getElementById("matchImage").style.width =
          String(document.getElementById("imageId").offsetWidth) + "px";
        this.showFivBoxClick = true;
      } else {
        this.showFivBoxClick = false;
      }
    }

    let pct = (keyframe - this.stageKeyframe[this.stageFlag]) / 20; // 比例
    if (
      keyframe <= this.stageKeyframe[this.stageFlag] + 20 &&
      keyframe >= this.stageKeyframe[this.stageFlag] - 20
    ) {
      if (this.stageFlag != 6) {
        // let scaleValue;
        // if(pct < 0) {       // 取消縮至最小功能
        //     // scaleValue = 1 - Math.abs(pct)
        // }
        // else {
        //     scaleValue = Math.pow(pct*20, pct*5)
        // }

        $(".showText")
          .eq(0)
          .css({
            opacity: 1 - Math.abs(pct),
          });
      } else if (this.stageFlag == 6) {
        // let scaleValue;
        $(".showText")
          .eq(0)
          .css({ opacity: 1 - Math.abs(pct) });

        if (
          keyframe <= this.stageKeyframe[this.stageFlag] + 12 &&
          keyframe >= this.stageKeyframe[this.stageFlag]
        ) {
          // this.changeTitleTxt(pct);
          $(".showText").eq(0).css({ opacity: 1 });
          $("#txt").css({
            marginLeft:
              ((keyframe - this.stageKeyframe[this.stageFlag]) / 11) * 20 + "%",
            marginTop:
              ((keyframe - this.stageKeyframe[this.stageFlag]) / 11) * 5 + "%",
          });
        } else {
          if (
            keyframe <= this.stageKeyframe[this.stageFlag] + 20 &&
            keyframe > this.stageKeyframe[this.stageFlag] + 12
          ) {
            $("#txt").css({
              marginLeft: "20%",
              marginTop: "5%",
            });
          }

          // if(pct < 0) {
          //     // scaleValue = 1 - Math.abs(pct)
          // }
          // else {
          //     scaleValue = Math.pow(pct*20, pct*5)
          // }
          //
          // $(".showText").eq(0).css({
          //     transform: 'scale('+ scaleValue +')'
          // });
        }
      }
      // else {
      //   $(".showText").eq(0).css({
      //     opacity: 1-Math.abs(pct),
      //   });
      //   this.changeTitleTxt(pct);
      // }
    }
    // else if(keyframe >= 571 && keyframe <= 598) {
    //     let rangePct = (keyframe-571)/27;
    //     $('#txt').css({
    //         top: (55-rangePct*50)+'%',
    //         left: (20-rangePct*15)+'%'
    //     });
    //     this.changeTitleTxt(pct);
    // }

    if (this.stageFlag == 6) {
      // 重新生成input字體大小(OMG)
      $(".omgInput").css("font-size", $(".omgInput").height() / 2);
    }
  }

  changeTitleTxt(pct) {
    if (
      (this.changeTxt == -1 || this.changeTxt != this.stageFlag) &&
      this.stageFlag != -1
    ) {
      // 判斷同一個區間是否為第一次進入
      this.changeTxt = this.stageFlag;
      let index = 0;
      let greeceTxt = [
        "Ժ",
        "﷼",
        "₴",
        "៛",
        "§",
        "₯",
        "Ѩ",
        "Թ",
        "₰",
        "Γ",
        "₠",
        "﷼",
        "∮",
        "∂",
        "≸",
        "℘",
        "℄",
        "ΐ",
        "ΰ",
        "έ",
        "φ",
        "ς",
        "⌘",
        "¶",
        "❡",
        "⁋",
        "⋛",
        "η",
        "ή",
        "ό",
        "৳",
        "δ",
        "ȴ",
      ];
      this.orignialTxt = [];

      setTimeout(() => {
        let rotateInterval = window.setInterval(() => {
          // 存原文字
          this.orignialTxt.push($(".bigText div").eq(index).text());

          // 轉為希臘文
          if (this.count > 736 || (this.count > 455 && this.count < 497)) {
            if (
              this.orignialTxt[index] != " " &&
              this.orignialTxt[index] != ""
            ) {
              $(".bigText div").eq(index).html(greeceTxt[index]);
            }
          } else {
            $(".bigText div").eq(index).html(greeceTxt[index]);
          }

          // 轉回原文字
          setTimeout(() => {
            if (this.count > 736 || (this.count > 455 && this.count < 497)) {
              if (this.orignialTxt[index] != " ") {
                $(".bigText div").eq(index).html(this.orignialTxt[index]);
              }
            } else {
              $(".bigText div").eq(index).html(this.orignialTxt[index]);
            }
            index++;
          }, 100);

          if (index >= $(".bigText div").length) {
            window.clearInterval(rotateInterval);
          }
        }, 200);
      }, 1000);

      if (this.stageFlag != 7) {
        $(".smallText")
          .eq(0)
          .css({
            opacity: 1 - Math.abs(pct),
          });
      }
    }
  }

  // 文字動畫
  TxtAnimation(keyframe) {
    const Wrate = $("#imageId").height() / 1083;
    const big_size = 100 * Wrate;
    const small_size = 20 * Wrate;

    if (keyframe === 0) {
      this.sayHello();
      $("#txt").css("top", "20%");
      $("#txt").css("left", "20%");
      $("#txt").css("text-align", "left");
    } else if (keyframe === 1) {
      $("#txt").css("top", "30%");
      $("#txt").css("left", "36%");
      $("#txt").css("text-align", "right");
    } else if (keyframe === 2) {
      $("#txt").css("top", "50%");
      $("#txt").css("left", "14%");
      $("#txt").css("text-align", "left");
    } else if (keyframe === 3) {
      $("#txt").css("top", "20%");
      $("#txt").css("left", "55%");
      $("#txt").css("text-align", "left");
    } else if (keyframe === 4) {
      $("#txt").css("top", "13%");
      $("#txt").css("left", "54%");
      $("#txt").css("text-align", "left");
    } else if (keyframe === 5) {
      $("#txt").css("top", "40%");
      $("#txt").css("left", "15%");
      $("#txt").css("text-align", "left");
    } else if (keyframe === 6) {
      $("#txt").css("top", "47%");
      $("#txt").css("left", "27%");
      $("#txt").css("text-align", "left");
    } else if (keyframe === 7) {
      $("#txt").css("top", "45%");
      $("#txt").css("left", "20%");
      $("#txt").css("text-align", "left");
    }

    $("#txt").css("margin-top", "");
    $("#txt").css("margin-left", "");

    setTimeout(() => {
      $(".bigText").css("font-size", big_size * 0.9 + "px");
      $(".bigText").css("line-height", big_size * 0.9 + "px");
      $(".smallText").css("font-size", small_size * 0.9 + "px");
    }, 10);
  }

  // 切割awards
  splitAwards() {
    let data = this.nowStepData[5].content.split("\n-");

    for (let i = 0; i < data.length; i += 2) {
      if (i == 0) {
        this.awardsArr.push(data[i] + "\n-" + data[i + 1]);
      } else {
        this.awardsArr.push("-" + data[i] + "\n-" + data[i + 1]);
      }
    }
  }

  // 下一個awards
  nextAwards(num) {
    this.awardsIndex += num;
    if (this.awardsIndex < 0) {
      this.awardsIndex = 0;
    } else if (this.awardsIndex > this.awardsArr.length - 1) {
      this.awardsIndex = this.awardsArr.length - 1;
    }
  }

  openMenu() {
    $(".headNavBar").addClass("active");
    $(".menuCon").addClass("active");
    $(".menuColCon").addClass("active");
    this.openDialog = true;

    this.dialog.closeAll();
  }

  closeMenu() {
    $(".menuCon .closeBtn").addClass("active");
    this.openDialog = false;
    setTimeout(function () {
      $(".headNavBar").removeClass("active");
      $(".menuCon").removeClass("active");
      $(".menuColCon").removeClass("active");
      $(".menuCon .closeBtn").removeClass("active");
    }, 300);
  }

  // 開啟抽屜dialog
  openDrawer(drawerIndex) {
    this.openDialog = true;
    let drawerDialog = this.dialog.open(DrawerDialogComponent, {
      autoFocus: false, // 不要預設一開始focus在某個input內
      disableClose: true,
      data: {
        // 傳值
        drawerIndex: drawerIndex,
      },
    });

    $(".cdk-overlay-pane").css({
      width: "100%",
      height: "100%",
      "max-width": "100vw",
    });

    // 當dialog關閉，可接收回傳值(尚未設定)
    drawerDialog.afterClosed().subscribe((result) => {
      this.openDialog = false;
    });
  }

  // 開啟書本dialog
  openBook(index) {
    if (!this.openDialog) {
      // 開啟書本
      this.bookIndex = index;
      this.openDialog = true;

      $(".page1").eq(index).addClass("active");
      $(".page2").eq(index).addClass("active");
      $(".bookList").eq(index).addClass("active");
      $(".bookList").eq(index).removeClass("pointer");
      $(".bookList").eq(index).removeClass("bookNormalStyle");
      $(".bookList")
        .eq(index)
        .removeClass("book" + index);

      this.setBookStyle(); // 設定書本大小

      setTimeout(() => {
        $(".bookImage1").eq(index).addClass("active");
        $(".bookImage2").eq(index).addClass("active");
        $(".page1").eq(index).addClass("active2");
        $(".page2").eq(index).addClass("active2");

        setTimeout(() => {
          let bookDialog = this.dialog.open(BookDialogComponent, {
            autoFocus: false, // 不要預設一開始focus在某個input內
            disableClose: true,
            data: {
              // 傳值
              bookIndex: index,
              bookData: this.nowBookData,
            },
          });

          $(".cdk-overlay-container").css({
            "z-index": 999,
          });

          // 當dialog關閉，可接收回傳值(尚未設定)
          bookDialog.afterClosed().subscribe((result) => {
            this.openBook(index);
            this.openDialog = false;
          });
        }, 1000);
      }, 1000);
    } else {
      // 關閉書本
      this.bookIndex = -1;

      $(".bookList").eq(index).addClass("bookNormalStyle");

      let bookWidth;
      if (index == 0) {
        bookWidth = document.getElementById("imageId").offsetWidth * 0.14;
      } else if (index == 1 || index == 2 || index == 5) {
        bookWidth = document.getElementById("imageId").offsetWidth * 0.12;
      } else if (index == 3) {
        bookWidth = document.getElementById("imageId").offsetWidth * 0.23;
      } else if (index == 4) {
        bookWidth = document.getElementById("imageId").offsetWidth * 0.17;
      }

      setTimeout(() => {
        $(".page1")
          .eq(index)
          .css("width", bookWidth / 2 + "px");
        $(".page2")
          .eq(index)
          .css("width", bookWidth / 2 + "px");
        $(".bookList")
          .eq(index)
          .addClass("book" + index);
        $(".bookImage1").eq(index).removeClass("active");
        $(".bookImage2").eq(index).removeClass("active");
        $(".page1").eq(index).removeClass("active2");
        $(".page2").eq(index).removeClass("active2");
        $(".page1").eq(index).removeClass("active");
        $(".page2").eq(index).removeClass("active");
        $(".bookList").eq(index).removeClass("active");
        $(".bookList").eq(index).addClass("pointer");
        $(".bookList").eq(index).css({ marginTop: "", marginLeft: "" });
        $(".bookNormalStyle")
          .eq(index)
          .css("width", bookWidth + "px");

        let rangeValue = 0;
        if ($(window).innerWidth() / $(window).innerHeight() >= 1.75) {
          // width:100%
          rangeValue =
            (document.getElementById("imageId").offsetHeight -
              $(window).innerHeight()) /
            2;
        }
        if (index == 0) {
          $(".book0").css({
            marginLeft:
              "-" +
              document.getElementById("imageId").offsetWidth * 0.22 +
              "px",
            marginTop:
              "-" +
              (document.getElementById("imageId").offsetWidth * 0.14 -
                rangeValue) +
              "px",
          });
        } else if (index == 1) {
          $(".book1").css({
            marginLeft:
              document.getElementById("imageId").offsetWidth * 0.08 + "px",
            marginTop:
              "-" +
              (document.getElementById("imageId").offsetWidth * 0.08 -
                rangeValue) +
              "px",
          });
        } else if (index == 2) {
          $(".book2").css({
            marginLeft:
              document.getElementById("imageId").offsetWidth * 0.36 + "px",
            marginTop:
              "-" +
              (document.getElementById("imageId").offsetWidth * 0.01 -
                rangeValue) +
              "px",
          });
        } else if (index == 3) {
          $(".book3").css({
            marginLeft:
              "-" +
              document.getElementById("imageId").offsetWidth * 0.02 +
              "px",
            marginTop:
              document.getElementById("imageId").offsetWidth * 0.15 -
              rangeValue +
              "px",
          });
        } else if (index == 4) {
          $(".book4").css({
            marginLeft:
              "-" +
              document.getElementById("imageId").offsetWidth * 0.42 +
              "px",
            marginTop:
              "-" +
              (document.getElementById("imageId").offsetWidth * 0.22 -
                rangeValue) +
              "px",
          });
        } else if (index == 5) {
          $(".book5").css({
            marginLeft:
              "-" +
              document.getElementById("imageId").offsetWidth * 0.42 +
              "px",
            marginTop:
              document.getElementById("imageId").offsetWidth * 0.18 -
              rangeValue +
              "px",
          });
        }

        this.openDialog = false;
      }, 1000);
    }
  }

  // 設定書本大小
  setBookStyle() {
    let rangeValue;
    if (($(window).innerHeight() - 120) * 1.485 >= $(window).innerWidth()) {
      rangeValue = $(window).innerWidth() / 1.485;
    } else {
      rangeValue = $(window).innerHeight();
    }

    if (!this.openDialog) {
      this.setBookSize();
    }

    $(".bookList.active").css({
      width: (rangeValue - 120) * 1.485 + "px",
      // 'height': rangeValue-120+'px',
      "margin-left": "-" + ((rangeValue - 120) * 1.485) / 2 + "px",
      "margin-top":
        "-" +
        ((rangeValue - 120) / 2 + ((rangeValue - 120) / 2) * 0.1 + 16) +
        "px",
    });
    $(".page1.active").css({
      width: (rangeValue - 120) * 0.7425 + "px",
    });
    $(".page2.active").css({
      width: (rangeValue - 120) * 0.7425 + "px",
    });

    if (this.openDialog) {
      setTimeout(() => {
        $(".bookList").eq(this.bookIndex).css("visibility", "hidden");
      }, 1500);
    }
  }

  // 點擊前書本的大小
  setBookSize() {
    let rangeValue = 0;
    if ($(window).innerWidth() / $(window).innerHeight() >= 1.75) {
      // width:100%
      rangeValue =
        (document.getElementById("imageId").offsetHeight -
          $(window).innerHeight()) /
        2;
    }

    setTimeout(() => {
      for (let i = 0; i < 6; i++) {
        let bookWidth;
        if (i == 0) {
          bookWidth = document.getElementById("imageId").offsetWidth * 0.14;
        } else if (i == 1 || i == 2 || i == 5) {
          bookWidth = document.getElementById("imageId").offsetWidth * 0.12;
        } else if (i == 3) {
          bookWidth = document.getElementById("imageId").offsetWidth * 0.23;
        } else if (i == 4) {
          bookWidth = document.getElementById("imageId").offsetWidth * 0.17;
        }
        $(".bookNormalStyle")
          .eq(i)
          .css("width", bookWidth + "px");
        $(".page1")
          .eq(i)
          .css("width", bookWidth / 2 - 2 + "px");
        $(".page2")
          .eq(i)
          .css("width", bookWidth / 2 - 2 + "px");
      }

      setTimeout(() => {
        $(".book0").css({
          marginLeft:
            "-" + document.getElementById("imageId").offsetWidth * 0.22 + "px",
          marginTop:
            "-" +
            (document.getElementById("imageId").offsetWidth * 0.14 -
              rangeValue) +
            "px",
        });
        $(".book1").css({
          marginLeft:
            document.getElementById("imageId").offsetWidth * 0.08 + "px",
          marginTop:
            "-" +
            (document.getElementById("imageId").offsetWidth * 0.08 -
              rangeValue) +
            "px",
        });
        $(".book2").css({
          marginLeft:
            document.getElementById("imageId").offsetWidth * 0.36 + "px",
          marginTop:
            "-" +
            (document.getElementById("imageId").offsetWidth * 0.01 -
              rangeValue) +
            "px",
        });
        $(".book3").css({
          marginLeft:
            "-" + document.getElementById("imageId").offsetWidth * 0.02 + "px",
          marginTop:
            document.getElementById("imageId").offsetWidth * 0.15 -
            rangeValue +
            "px",
        });
        $(".book4").css({
          marginLeft:
            "-" + document.getElementById("imageId").offsetWidth * 0.42 + "px",
          marginTop:
            "-" +
            (document.getElementById("imageId").offsetWidth * 0.22 -
              rangeValue) +
            "px",
        });
        $(".book5").css({
          marginLeft:
            "-" + document.getElementById("imageId").offsetWidth * 0.42 + "px",
          marginTop:
            document.getElementById("imageId").offsetWidth * 0.18 -
            rangeValue +
            "px",
        });
      }, 50);
    }, 50);
  }

  // 書本消失動畫(內縮後消失)
  bookFadeAway() {
    setTimeout(() => {
      $(".book0").css({ marginLeft: "0px", marginTop: "0px", opacity: 0 });
      $(".book1").css({ marginLeft: "0px", marginTop: "0px", opacity: 0 });
      $(".book2").css({ marginLeft: "0px", marginTop: "0px", opacity: 0 });
      $(".book3").css({ marginLeft: "0px", marginTop: "0px", opacity: 0 });
      $(".book4").css({ marginLeft: "0px", marginTop: "0px", opacity: 0 });
      $(".book5").css({ marginLeft: "0px", marginTop: "0px", opacity: 0 });

      setTimeout(() => {
        this.showFivBookClick = false;
      }, 1000);
    }, 50);
  }

  // 開啟影片dialog
  openVideo() {
    this.openDialog = true;
    let videoListDialogComponent = this.dialog.open(VideoListDialogComponent, {
      autoFocus: false, // 不要預設一開始focus在某個input內
      disableClose: true,
      data: {
        // 傳值
        videoData: this.videoData,
      },
    });

    $(".cdk-overlay-pane").css({
      width: "100%",
      height: "100%",
      "max-width": "100vw",
    });

    // 當dialog關閉，可接收回傳值(尚未設定)
    videoListDialogComponent.afterClosed().subscribe((result) => {
      this.openDialog = false;
    });
  }

  // // 水流動畫(上升:up、下降:down)
  // waterAnimation(flag) {
  //     let temp1;
  //     let temp2;
  //     if(this.waterFlag == '') {
  //         temp1 = 0;
  //         temp2 = Math.round($('#videoImage').innerHeight());
  //
  //         // 水流水平流速
  //         let timeH = window.setInterval(() => {
  //             if(temp1 > ((0-$('#videoImage').innerWidth())*39)) {
  //                 if(this.waterFlag == 'up') {
  //                     $(".waterImage").css({'left': temp1--});
  //                 }
  //                 else if(this.waterFlag == 'down') {
  //                     $(".waterImage").css({'left': temp1++});
  //                 }
  //             }
  //         }, 10);
  //
  //         // 水流垂直流速
  //         let timeV = window.setInterval(() => {
  //             if(Math.round($('#videoImage').innerHeight()) >= temp2 && temp2 > -8) {
  //                 if(this.waterFlag == 'up') {
  //                     $(".waterImage").css({'top': temp2--});
  //                 }
  //                 else if(this.waterFlag == 'down') {
  //                     $(".waterImage").css({'top': temp2++});
  //                 }
  //             }
  //             else if(temp2 == -8) {
  //                 $(".waterImage").css({'top': temp2++});
  //             }
  //             else {
  //                 window.clearInterval(timeH);
  //                 window.clearInterval(timeV);
  //                 this.waterFlag = '';
  //             }
  //         }, 70);
  //     }
  //     this.waterFlag = flag;
  // }

  // 前往OMG輸入階段
  toOMG() {
    this.stopWait = true;
    this.imageRunUp(676, 688);
  }

  // 比對OMG
  matchOMG(inputO, inputM, inputG) {
    if (
      inputO.toUpperCase() == "O" &&
      inputM.toUpperCase() == "M" &&
      inputG.toUpperCase() == "G"
    ) {
      this.stopWait = true;
      this.imageRunUp(688, 757);
    }
  }

  // 比對OMG輸入，跳至下一個input
  checkInputTxt(index, event) {
    if (index == 0) {
      if (event.key != "Backspace") {
        $(".omgInput")
          .eq(index + 1)
          .focus();
      }
    } else if (index == 1) {
      if (event.key != "Backspace") {
        $(".omgInput")
          .eq(index + 1)
          .focus();
      } else {
        $(".omgInput")
          .eq(index - 1)
          .focus();
      }
    } else if (index == 2) {
      if (event.key == "Backspace") {
        $(".omgInput")
          .eq(index - 1)
          .focus();
      }
    }
  }
}
