import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagerComponent implements OnInit {

  leftBarTab = 0;       // 階段文字(0)、成員圖片(1)、文章分類(2)、治癒影驗(3)
  subTitleTab = 0;      // 新增(0)、修改(1)、刪除(2)
  showLoading = 0;      // 是否顯示loading
  accountType = Number(sessionStorage.getItem('accountType'));          // 帳號類型

  constructor(private router: Router) { }

  ngOnInit() {
      if(sessionStorage.getItem('account') == null) {
          this.router.navigate(['/BackLogin']);
      }
      this.initializeSubTitle();
  }

  // 初始化
  initializeSubTitle() {
      for(let i=0; i<3; i++) {
          $('.subTitleBtn').eq(i).removeClass('active');
      }

      if(this.leftBarTab == 0) {
          this.subTitleTab = 1;
          $('.subTitleBtn').eq(1).addClass('active');
      }
      else {
          this.subTitleTab = 0;
          $('.subTitleBtn').eq(0).addClass('active');
      }
  }

  // 改變新增修改刪除tab
  changeSubTitle(index) {
      // 移除原tab的focus效果，新tab加入效果
      $('.subTitleBtn').eq(this.subTitleTab).removeClass('active');
      $('.subTitleBtn').eq(index).addClass('active');

      this.subTitleTab = index;
  }

  // 改變左側tab
  changeLeftTab(index) {
      // 移除原tab的focus效果，新tab加入效果
      $('.leftBarBtn').eq(this.leftBarTab).removeClass('active');
      $('.leftBarBtn').eq(index).addClass('active');

      this.leftBarTab = index;
      this.initializeSubTitle();
  }

  // 判斷是否顯示loading
  showLoadDiv(num) {
    this.showLoading = num;

    setTimeout(() => {
        document.getElementById("loading").style.height = window.innerHeight+"px";
    });

    if(num == 2 || num == 3 || num == 4) {
        setTimeout(() => {      // 顯示1.5秒
            this.showLoading = 0;
        }, 1500);
    }
  }

  // 登出
  logOut() {
      sessionStorage.removeItem("account");
      this.router.navigate(['/BackLogin']);
  }

}
