// 上架用
export const httpURL: string = "/heartbeatMobile";
export const httpBackURL: string =
  "/heartbeatServer/BackApi/getheartbeatBackapi?";
export const httpShowURL: string = "/heartbeatServer/api/";
export const httpImgURL: string = "/heartbeatServer/FileUpload/";
export const httpGetImgURL: string = "/heartbeatServer/static/images/";

// const rootURL: string = "https://jti.wetopya.com/heartbeatServer/";
// export const httpURL: string = "/heartbeatMobile";
// export const httpBackURL: string = rootURL + "BackApi/getheartbeatBackapi?";
// export const httpShowURL: string = rootURL + "api/";
// export const httpImgURL: string = rootURL + "FileUpload/";
// export const httpGetImgURL: string = rootURL + "static/images/";

// ng build --base-href=/heartbeatWeb/ --aot
