import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import * as globalVar from "../../globalVar";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnChanges {

  accountForm: FormGroup;
  dataArr = [];         // 資料集
  extendIndex = -1;     // 要伸展開的項目
  modify = false;       // 是否修改資料

  @Input() subTitleTab;     // 新增、修改
  @Input() leftBarTab;      // 帳號管理(4)
  @Output('loadingComponent') loadingComponent = new EventEmitter<number>();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  ngOnChanges(changeItems) {
      if(changeItems.subTitleTab) {
          if(this.subTitleTab == 0) {
              this.setValue(0);
          }
          else if(this.subTitleTab == 1 || this.subTitleTab == 2) {     // 修改、刪除
              this.extendIndex = -1;

              this.accountForm = new FormGroup({
                  type: new FormControl('1', Validators.required),
                  acc: new FormControl('', Validators.required),
                  pass: new FormControl('', Validators.required),
                  passRe: new FormControl('', Validators.required)
              });
              this.searchData();
          }
      }
      else if(changeItems.leftBarTab && this.leftBarTab == 4) {
          this.setValue(0);
      }
  }

  // 搜尋資料
  searchData() {
      this.http.post(globalVar.httpBackURL+'x=11', null).subscribe(
          (res:any) => {
              this.dataArr = res;
          }
      )
  }

  onSubmit(value) {

  }

  // 將項目伸展開
  extendTab(index) {
      this.modify = false;
      if(this.extendIndex == -1) {        // 目前沒開啟的項目，直接展開
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
      else if(this.extendIndex == index) {        // 點擊開啟的項目，關閉此項目
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          this.extendIndex = -1;
      }
      else {        // 已有開啟項目，點擊非開啟的項目，項目一開一關
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
  }

  // 設定表單欄位
  setValue(index) {
      if(this.subTitleTab == 0) {       // 新增
          this.accountForm = new FormGroup({
              type: new FormControl('1', Validators.required),
              acc: new FormControl('', Validators.required),
              pass: new FormControl('', Validators.required),
              passRe: new FormControl('', Validators.required)
          });
      }
      else if(this.subTitleTab == 1 || this.subTitleTab == 2) {       // 修改、刪除
          this.accountForm.get('acc').reset({value: this.dataArr[index], disabled: true});
      }
  }

  // 修改
  modifyData() {
      this.modify = true;
      this.accountForm.get('pass').reset({value: '', disabled: false}, Validators.required);
      this.accountForm.get('passRe').reset({value: '', disabled: false}, Validators.required);
  }

  // 取消修改
  cancel() {
      this.accountForm.get('pass').reset({value: '', disabled: false}, Validators.required);
      this.accountForm.get('passRe').reset({value: '', disabled: false}, Validators.required);
      this.modify = false;
  }

  // 送出修改
  saveData() {
    if(this.accountForm.get('pass').value == this.accountForm.get('passRe').value) {
        this.loadingComponent.emit(1);
        let formData = new FormData();
        formData.append('account', this.accountForm.get('acc').value);
        formData.append('password', this.accountForm.get('pass').value);

        if(this.subTitleTab == 0) {       // 新增
            formData.append('type', this.accountForm.get('type').value);

            this.http.post(globalVar.httpBackURL+'x=5', formData).subscribe(
                (res:any) => {
                    if(res[0].Response == 1) {
                        this.accountForm.get('acc').reset('', Validators.required);
                        this.accountForm.get('pass').reset('', Validators.required);
                        this.accountForm.get('passRe').reset('', Validators.required);

                        this.loadingComponent.emit(2);
                    }
                    else if(res[0].Response == 3) {
                        this.loadingComponent.emit(4);
                    }
                }
            );
        }
        else if(this.subTitleTab == 1) {     // 修改
            this.http.post(globalVar.httpBackURL+'x=12', formData).subscribe(
                (res:any) => {
                    if(res[0].Response == 1) {
                        this.accountForm.get('pass').reset('', Validators.required);
                        this.accountForm.get('passRe').reset('', Validators.required);
                        this.modify = false;

                        this.loadingComponent.emit(2);
                    }
                    else if(res[0].Response == 3) {
                        this.loadingComponent.emit(3);
                    }
                }
            );
        }
    }
    else {
      alert("兩次密碼不相同");
        this.accountForm.get('pass').reset('');
        this.accountForm.get('passRe').reset('');
    }
  }

  // 刪除帳號
  deleteData(index) {
      this.loadingComponent.emit(1);

      let formData = new FormData();
      formData.append('account', this.dataArr[index]);
      this.http.post(globalVar.httpBackURL+'x=10', formData).subscribe(
          (res:any) => {
              if(res[0].Response == 1) {
                  this.dataArr.splice(index, 1);

                  this.loadingComponent.emit(4);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
          }
      );
  }
}
