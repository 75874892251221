<form (ngSubmit)="onSubmit(worksForm)" [formGroup]="worksForm">
  <select class="selectMargin" formControlName="type" (change)="changeType()">
    <option value="1">TECHNOLOGY</option>
    <option value="2">WEBSITE</option>
    <option value="3">PRINT AD</option>
    <option value="4">IMC.</option>
    <option value="5">VIDEO</option>
    <option value="6">MUSIC</option>
    <option value="7">OTHERS</option>
  </select><br>
  <div class="area_con" *ngIf="subTitleTab == 0">
    <div class="itemStyle">
      <div class="alignRight" style="padding: 10px;">
        <input placeholder="中文標題" class="inputSize" type="text" formControlName="chtitle"><br><br>
        <input placeholder="英文標題" class="inputSize" type="text" formControlName="entitle"><br><br>
        <input placeholder="外部連結" class="inputSize" type="text" formControlName="url"><br><br>
        <input class="inputSize" name="upload[]" type="file" placeholder="圖片上傳" accept=".jpg, .jpeg, .png" (change)="handleInput($event.target.files)"  formControlName="image"><br>
        <div style="text-align: left">
          <img [src]="imageUrl" style="margin-top: 10px; height: 250px" *ngIf="showImage == 1">
        </div><br>
        <button class="backBtnStyle btnDanger btnSpace" (click)="addImage(-1)">送出</button>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 1">
    <div class="itemStyle" *ngFor="let data of dataArr; let i = index;">
      <div class="extendTitle" (click)="extendTab(i)">
        <h5 class="titleName">{{data.chtitle}}</h5>
        <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
        <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
      </div>
      <div class="extendContent">
        <input placeholder="中文標題" class="inputSize" type="text" formControlName="chtitle"><br><br>
        <input placeholder="英文標題" class="inputSize" type="text" formControlName="entitle"><br><br>
        <input placeholder="外部連結" class="inputSize" type="text" formControlName="url"><br><br>
        <input class="inputSize" name="upload[]" type="file" placeholder="圖片上傳" accept=".jpg, .jpeg, .png" (change)="handleInput($event.target.files)" formControlName="image" *ngIf="modify"><br>
        <div style="text-align: left">
          <img [src]="imageUrl" style="margin-top: 10px; height: 250px" *ngIf="showImage == 1">
        </div><br>
        <div class="alignRight">
          <button class="backBtnStyle btnWarn" *ngIf="!modify" (click)="modifyData(i)">修改</button>
          <button class="backBtnStyle btnWarn" *ngIf="modify" (click)="cancel(i)">取消</button>
          <button class="backBtnStyle btnDanger btnSpace" *ngIf="modify" (click)="addImage(i)">送出</button>
        </div>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 2">
    <div class="itemStyle" *ngFor="let data of dataArr; let i = index;">
      <table class="table_delete">
        <tr>
          <td>
            <div class="extendTitle" (click)="extendTab(i)">
              <h5 class="titleName">{{data.chtitle}}</h5>
              <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
              <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
            </div>
            <div class="extendContent">
              <input placeholder="中文標題" class="inputSize" type="text" formControlName="chtitle"><br><br>
              <input placeholder="英文標題" class="inputSize" type="text" formControlName="entitle"><br><br>
              <input placeholder="外部連結" class="inputSize" type="text" formControlName="url"><br><br>
              <div style="text-align: left">
                <img [src]="imageUrl" style="margin-top: 10px; height: 250px" *ngIf="showImage == 1">
              </div><br>
            </div>
          </td>
          <td class="td_delete">
            <div class="floatR inline_style">
              <button class="backBtnStyle btnDanger btnSpace deleteBtn_noPad" (click)="deleteImage(i)">刪除</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>