<div class="container loginDiv">
  <div class="imgPosition">
    <img class="manStyle" src="assets/logo.png">
  </div>
  <div class="formContainer">
    <div align="center">
      <h4><strong>管理者登入</strong></h4>
    </div>
    <form (ngSubmit)="onSubmit(signIn)" [formGroup]="signIn">
      <p>
        <label for="account">帳號</label><br>
        <input id="account" class="inputSize" type="text" formControlName="account">
      </p>
      <p>
        <label for="password">密碼</label><br>
        <input id="password" class="inputSize" type="password" formControlName="password">
      </p>
      <input id="remember" class="checkboxStyle" type="checkbox" formControlName="remember"><label class="rememberTxt" for="remember">記住密碼</label><br><br>
      <div align="center">
        <button class="btnStyle btnOut" type="button" color="primary" mat-flat-button (click)="leave()">離開</button>
        <button class="btnStyle btnIn" type="submit" color="primary" mat-flat-button>登入</button>
      </div>
    </form>
  </div>
</div>
