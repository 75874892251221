<form (ngSubmit)="onSubmit(memberForm)" [formGroup]="memberForm">
  <select class="selectMargin" formControlName="language" (change)="langChange()">
    <option value="1">中文</option>
    <option value="2">英文</option>
  </select><br>
  <div class="area_con" *ngIf="subTitleTab == 0">
    <div class="itemStyle">
      <div class="alignRight" style="padding: 10px;">
        <input placeholder="職稱" class="inputSize" type="text" formControlName="jobtitle"><br><br>
        <input placeholder="電話" class="inputSize" type="text" formControlName="contact"><br><br>
        <input placeholder="電子郵件" class="inputSize" type="text" formControlName="email"><br><br>
        <div style="text-align: left;">
          <input class="inputSize sameLine" name="upload[]" type="file" placeholder="左半圖上傳" accept=".jpg, .jpeg, .png" (change)="handleInput($event.target.files, 0)"  formControlName="leftImage">
          <input class="inputSize sameLine" name="upload[]" type="file" placeholder="右半圖上傳" accept=".jpg, .jpeg, .png" (change)="handleInput($event.target.files, 1)"  formControlName="rightImage"><br>
          <div class="sameLine" style="text-align: left">
            <img [src]="imageUrl[0]" style="margin-top: 10px; width: 100%;" *ngIf="showImage[0] == 1">
          </div>
          <div class="sameLine" style="text-align: left">
            <img [src]="imageUrl[1]" style="margin-top: 10px; width: 100%;" *ngIf="showImage[1] == 1">
          </div>
        </div><br><br>
        <button class="backBtnStyle btnDanger btnSpace" (click)="addImage(-1, 0)">送出</button>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 1">
    <div class="itemStyle" *ngFor="let data of nowDataArr; let i = index;">
      <div class="extendTitle" (click)="extendTab(i)">
        <h5 class="titleName">{{data.jobtitle}}</h5>
        <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
        <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
      </div>
      <div class="extendContent">
        <input placeholder="職稱" class="inputSize" type="text" formControlName="jobtitle"><br><br>
        <input placeholder="電話" class="inputSize" type="text" formControlName="contact"><br><br>
        <input placeholder="電子郵件" class="inputSize" type="text" formControlName="email"><br><br>
        <div style="text-align: left;">
          <input class="inputSize sameLine" name="upload[]" type="file" placeholder="左半圖上傳" accept=".jpg, .jpeg, .png" (change)="handleInput($event.target.files, 0)"  formControlName="leftImage" *ngIf="modify">
          <input class="inputSize sameLine" name="upload[]" type="file" placeholder="右半圖上傳" accept=".jpg, .jpeg, .png" (change)="handleInput($event.target.files, 1)"  formControlName="rightImage" *ngIf="modify"><br *ngIf="modify">
          <div class="sameLine" style="text-align: left">
            <img [src]="imageUrl[0]" style="margin-top: 10px; width: 100%;" *ngIf="showImage[0] == 1">
          </div>
          <div class="sameLine" style="text-align: left">
            <img [src]="imageUrl[1]" style="margin-top: 10px; width: 100%;" *ngIf="showImage[1] == 1">
          </div>
        </div><br><br>
        <div class="alignRight">
          <button class="backBtnStyle btnWarn" *ngIf="!modify" (click)="modifyData(i)">修改</button>
          <button class="backBtnStyle btnWarn" *ngIf="modify" (click)="cancel(i)">取消</button>
          <button class="backBtnStyle btnDanger btnSpace" *ngIf="modify" (click)="addImage(i, 0)">送出</button>
        </div>
      </div>
    </div>
  </div>
  <div class="area_con" *ngIf="subTitleTab == 2">
    <div class="itemStyle" *ngFor="let data of nowDataArr; let i = index;">
      <table class="table_delete">
        <tr>
          <td>
            <div class="extendTitle" (click)="extendTab(i)">
              <h5 class="titleName">{{data.jobtitle}}</h5>
              <label class="downIcon" *ngIf="this.extendIndex != i"><i class="fas fa-sort-down"></i></label>
              <label class="downIcon" *ngIf="this.extendIndex == i"><i class="fas fa-caret-up"></i></label>
            </div>
            <div class="extendContent">
              <input placeholder="職稱" class="inputSize" type="text" formControlName="jobtitle"><br><br>
              <input placeholder="電話" class="inputSize" type="text" formControlName="contact"><br><br>
              <input placeholder="電子郵件" class="inputSize" type="text" formControlName="email"><br><br>
              <div style="text-align: left;">
                <div class="sameLine" style="text-align: left">
                  <img [src]="imageUrl[0]" style="margin-top: 10px; width: 100%;" *ngIf="showImage[0] == 1">
                </div>
                <div class="sameLine" style="text-align: left">
                  <img [src]="imageUrl[1]" style="margin-top: 10px; width: 100%;" *ngIf="showImage[1] == 1">
                </div>
              </div><br>
            </div>
          </td>
          <td class="td_delete">
            <div class="floatR inline_style">
              <button class="backBtnStyle btnDanger btnSpace deleteBtn_noPad" (click)="deleteImage(i)">刪除</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>