import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import * as globalVar from "../../globalVar";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit, OnChanges {

  videoForm: FormGroup;
  dataArr = [];         // 資料集
  extendIndex = -1;     // 要伸展開的項目
  modify = false;       // 是否修改資料

  @Input() subTitleTab;     // 新增、修改
  @Input() leftBarTab;      // 治癒影驗(3)
  @Output('loadingComponent') loadingComponent = new EventEmitter<number>();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  ngOnChanges(changeItems) {
      if(changeItems.subTitleTab) {
          if(this.subTitleTab == 0) {     // 新增
              this.setValue(0);
          }
          else if(this.subTitleTab == 1 || this.subTitleTab == 2) {     // 修改、刪除
              this.extendIndex = -1;

              this.videoForm = new FormGroup({
                  chtitle: new FormControl('', Validators.required),
                  entitle: new FormControl('', Validators.required),
                  fblinkurl: new FormControl('', Validators.required)
              });
              this.searchData();
          }
      }
      else if(changeItems.leftBarTab && this.leftBarTab == 3) {
          this.setValue(0);
      }
  }

  // 搜尋資料
  searchData() {
      this.http.post(globalVar.httpShowURL+'infos?x=3', null).subscribe(
          (res:any) => {
              this.dataArr = res;
          }
      )
  }

  onSubmit(value) {

  }

  // 將項目伸展開
  extendTab(index) {
      this.modify = false;
      if(this.extendIndex == -1) {        // 目前沒開啟的項目，直接展開
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
      else if(this.extendIndex == index) {        // 點擊開啟的項目，關閉此項目
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          this.extendIndex = -1;
      }
      else {        // 已有開啟項目，點擊非開啟的項目，項目一開一關
          $('.extendContent').eq(this.extendIndex).removeClass('active');
          $('.extendContent').eq(index).addClass('active');
          this.extendIndex = index;
          this.setValue(index);
      }
  }

  // 設定表單欄位
  setValue(index) {
      if(this.subTitleTab == 0) {       // 新增
          this.videoForm = new FormGroup({
              chtitle: new FormControl('', Validators.required),
              entitle: new FormControl('', Validators.required),
              fblinkurl: new FormControl('', Validators.required)
          });
      }
      else if(this.subTitleTab == 1 || this.subTitleTab == 2) {       // 修改、刪除
          this.videoForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: true});
          this.videoForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: true});
          this.videoForm.get('fblinkurl').reset({value: this.dataArr[index].fblinkurl, disabled: true});
      }
  }

  // 修改
  modifyData(index) {
      this.modify = true;
      this.videoForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: false}, Validators.required);
      this.videoForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: false}, Validators.required);
      this.videoForm.get('fblinkurl').reset({value: this.dataArr[index].fblinkurl, disabled: false}, Validators.required);
  }

  // 取消修改
  cancel(index) {
      this.videoForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: true});
      this.videoForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: true});
      this.videoForm.get('fblinkurl').reset({value: this.dataArr[index].fblinkurl, disabled: true});
      this.modify = false;
  }

  // 送出修改
  saveData(index) {
    if(!this.videoForm.invalid) {
        this.loadingComponent.emit(1);
        let formData = new FormData();
        if(index == -1) {     // 新增
            formData.append('id', '');
        }
        else {      // 修改
            formData.append('id', this.dataArr[index].id);
        }
        formData.append('chtitle', this.videoForm.get('chtitle').value);
        formData.append('entitle', this.videoForm.get('entitle').value);
        formData.append('url', this.videoForm.get('fblinkurl').value);

        this.http.post(globalVar.httpBackURL+'x=3', formData).subscribe(
            (res:any) => {
                if(res[0].Response == 1) {
                    if(index == -1) {
                        this.videoForm.get('chtitle').reset({value: '', disabled: false}, Validators.required);
                        this.videoForm.get('entitle').reset({value: '', disabled: false}, Validators.required);
                        this.videoForm.get('fblinkurl').reset({value: '', disabled: false}, Validators.required);
                    }
                    else {
                        this.dataArr[index].chtitle = this.videoForm.get('chtitle').value;
                        this.dataArr[index].entitle = this.videoForm.get('entitle').value;
                        this.dataArr[index].fblinkurl = this.videoForm.get('fblinkurl').value;
                        this.videoForm.get('chtitle').reset({value: this.dataArr[index].chtitle, disabled: true}, Validators.required);
                        this.videoForm.get('entitle').reset({value: this.dataArr[index].entitle, disabled: true}, Validators.required);
                        this.videoForm.get('fblinkurl').reset({value: this.dataArr[index].fblinkurl, disabled: true}, Validators.required);
                        this.modify = false;
                    }

                    this.loadingComponent.emit(2);
                }
                else if(res[0].Response == 3) {
                    this.loadingComponent.emit(4);
                }
            }
        )
    }
    else {
      alert("請填寫所有欄位！");
    }
  }

  // 刪除資料
  deleteData(index) {
    if(confirm("是否確定刪除?")) {
        this.loadingComponent.emit(1);
        let formData = new FormData();
        formData.append('id', this.dataArr[index].id);

        this.http.post(globalVar.httpBackURL+'x=8', formData).subscribe(
            (res:any) => {
              if(res[0].Response == 1) {
                  this.dataArr.splice(index, 1);
                  this.loadingComponent.emit(4);
              }
              else if(res[0].Response == 3) {
                  this.loadingComponent.emit(3);
              }
            }
        );
    }
  }

}
